import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { Category, Merchant } from '../services/Interfaces';
import { getCategories, getTrendingMerchants } from '../services/api';

export type State = {
  categories?: Category[];
  trendingMerchants?: Merchant[];
};

export const StoreContext = createContext<State>({});

export const DataContext: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<State>({});

  useEffect(() => {
    getCategories().then(categories => setState(prev => ({ ...prev, categories })));
    getTrendingMerchants().then(featuredMerchants => setState(prev => ({ ...prev, trendingMerchants: featuredMerchants })));
  }, []);

  return <StoreContext.Provider value={state}>{children}</StoreContext.Provider>;
};

export default DataContext;
