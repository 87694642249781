import { FC, ReactNode, CSSProperties } from 'react';
import { Link } from '@mui/material';
import { SxProps } from '@mui/system';
import { Offer } from '../../services/Interfaces';
import { PARAM_NAME_REVEAL_VOUCHER_CODE } from '../../configurations/constants';

const openMerchantSite = (offer: Offer) => {
  window.open(`/${offer.merchant.urlSlug}?${PARAM_NAME_REVEAL_VOUCHER_CODE}=${offer.id}`, '_blank');
};

const OpenMerchantSiteLink: FC<{ children: ReactNode; offer: Offer; style?: CSSProperties; sx?: SxProps }> = ({
  children,
  offer,
  style,
  sx
}) => {
  return (
    <Link href={offer.url} onClick={() => openMerchantSite(offer)} style={style} sx={sx}>
      {children}
    </Link>
  );
};

OpenMerchantSiteLink.defaultProps = {
  style: undefined,
  sx: undefined
};

export { OpenMerchantSiteLink, openMerchantSite };
