import { Box, useTheme } from '@mui/system';
import { Button, Skeleton, Typography, Link } from '@mui/material';
import { Merchant } from '../../services/Interfaces';
import { getMerchantBusinessTypeBadge } from '../MerchantBusinessTypeBadge/MerchantBusinessTypeBadge';

const MerchantTitle = ({ merchant }: { merchant?: Merchant }) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', marginBottom: theme.spacing(3) }}>
      <Box
        sx={{
          width: '100px',
          height: '100px',
          marginRight: theme.spacing(2),
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: `${theme.shape.borderRadius}px`,
          overflow: 'hidden',
          display: 'flex'
        }}
      >
        {!merchant ? (
          <Skeleton variant="rectangular" width="100%" height="100%" />
        ) : (
          <img src={merchant.logo} alt={merchant.name} width="100%" height="100%" style={{ objectFit: 'cover' }} />
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Typography component="h1" variant="h4">
          {!merchant ? <Skeleton variant="text" width="30%" /> : merchant.name}
        </Typography>
        <Typography component="h2" variant="h6">
          {!merchant ? <Skeleton variant="text" width="60%" /> : merchant.description}
        </Typography>
        {merchant ? (
          <Box marginBottom={1} sx={{ float: 'right' }}>
            {getMerchantBusinessTypeBadge(merchant)}
          </Box>
        ) : (
          ''
        )}
        {merchant ? (
          <Box>
            {merchant.url ? (
              <Button component={Link} href={merchant.url} target="_blank" variant="outlined" size="small">
                Visit website
              </Button>
            ) : (
              ''
            )}
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

MerchantTitle.defaultProps = {
  merchant: undefined
};

export default MerchantTitle;
