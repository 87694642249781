import { Box, useTheme } from '@mui/system';
import { Skeleton, Typography } from '@mui/material';
import { Category } from '../../services/Interfaces';

const CategoryTitle = ({ category }: { category?: Category }) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', marginBottom: theme.spacing(6) }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        {!category ? (
          <Typography variant="h1" sx={{ margin: '0 auto', width: '33%' }}>
            <Skeleton variant="text" />
          </Typography>
        ) : (
          <Typography
            component="h1"
            variant="h1"
            color="primary"
            fontWeight="600"
            sx={{
              position: 'relative',
              margin: '0 auto',

              '&:after': {
                content: '""',
                position: 'absolute',
                zIndex: -1,
                bottom: '-0.11em',
                left: '-.5em',
                right: '-.5em',
                height: '0.5em',
                borderRadius: '50px',
                backgroundColor: theme.palette.secondary.main
              }
            }}
          >
            {!category ? <Skeleton variant="text" width="30%" /> : category.name}
          </Typography>
        )}
        {/* <Typography component="h2" variant="h6"> */}
        {/*  {!category ? <Skeleton variant="text" width="60%" /> : category.description} */}
        {/* </Typography> */}
      </Box>
    </Box>
  );
};

CategoryTitle.defaultProps = {
  category: undefined
};

export default CategoryTitle;
