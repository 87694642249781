import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import OfferPreviewCard from '../OfferPreviewCard/OfferPreviewCard';
import { Offer } from '../../services/Interfaces';

const OfferStrip = ({
  stripTitle,
  items,
  small,
  previewQuantity,
  isLoading
}: {
  stripTitle: string;
  items: Offer[];
  small?: boolean;
  previewQuantity?: number;
  isLoading?: boolean;
}) => {
  const theme = useTheme();
  const itemsToShow: Offer[] | undefined[] = isLoading ? [...Array(previewQuantity)] : items;

  return (
    <>
      <Box
        position="relative"
        sx={{
          [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
          }
        }}
      >
        <Typography variant="h3" component="h3" color="primary" fontWeight="600" gutterBottom sx={{ mt: 7, mb: 5 }}>
          {stripTitle}
        </Typography>
        {/* <Link */}
        {/*  variant="h6" */}
        {/*  underline="hover" */}
        {/*  href="/" */}
        {/*  sx={{ */}
        {/*    lineHeight: '2.43rem', */}
        {/*    position: 'absolute', */}
        {/*    right: 0, */}
        {/*    top: 0, */}
        {/*    [theme.breakpoints.down('sm')]: { */}
        {/*      fontSize: '0.8rem' */}
        {/*    } */}
        {/*  }} */}
        {/* > */}
        {/*  View all offers */}
        {/* </Link> */}
      </Box>
      <Grid container columnSpacing={{ xs: 1, sm: 3 }} rowSpacing={2}>
        {itemsToShow.map((item, index) => (
          <Grid item md={small ? 3 : 4} sm={small ? 4 : 6} xs={small ? 6 : 12} key={item ? item.id : index} container>
            <OfferPreviewCard offer={item} small={small} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

OfferStrip.defaultProps = {
  small: false,
  previewQuantity: 6,
  isLoading: false
};

export default OfferStrip;
