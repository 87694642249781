import { Route, Routes } from 'react-router-dom';
import Homepage from '../../pages/Homepage/Homepage';
import About from '../../pages/About/About';
import SiteLayout from '../../pages/SiteLayout/SiteLayout';
import ContactUs from '../../pages/ContactUs/ContactUs';
import TermsOfService from '../../pages/TermsOfService/TermsOfService';
import CookiePolicy from '../../pages/CookiePolicy/CookiePolicy';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
import MerchantDetails from '../../pages/MerchantDetails/MerchantDetails';
import Category from '../../pages/Category/Category';
import DataContext from '../../DataContext/DataContext';
import MerchantSignup from '../../pages/MerchantSignup/MerchantSignup';
import SignUp from '../../pages/SignUp/SignUp';
import BrandDirectory from '../../pages/BrandDirectory/BrandDirectory';

const App = () => {
  return (
    <DataContext>
      <Routes>
        <Route path="/" element={<SiteLayout />}>
          <Route path="/" element={<Homepage />} />
          <Route path="about" element={<About />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/:merchantUrlSlug" element={<MerchantDetails />} />
          <Route path="/category/:categoryUrlSlug" element={<Category />} />
          <Route path="/merchant-signup" element={<MerchantSignup />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/brands" element={<BrandDirectory />} />
        </Route>
      </Routes>
    </DataContext>
  );
};

export default App;
