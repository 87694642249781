import { ButtonUnstyled, ButtonUnstyledProps } from '@mui/base';
import { styled } from '@mui/system';

const multiFactor = 1.2;

const CustomButtonRoot = styled('a')(({ theme }) => {
  const buttonMainBackgroundColor = theme.palette.secondary.main;
  const buttonMainColor = theme.palette.text.secondary;

  return {
    width: '100%',
    minWidth: '200px',
    height: `${2.5 * multiFactor}rem`,
    lineHeight: `${2.5 * multiFactor}rem`,
    padding: `0 ${theme.spacing(2)}`,
    boxSizing: 'border-box',
    backgroundColor: buttonMainBackgroundColor,
    color: buttonMainColor,
    display: 'block',
    textDecoration: 'none',
    position: 'relative',
    borderRadius: `${theme.shape.borderRadius}px`,
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'center',
    outline: 'none',

    '&:before': {
      backgroundImage: `linear-gradient(38deg, #119081, ${buttonMainBackgroundColor} 49%,transparent 50%,transparent)`,
      backgroundPosition: `0 ${0.375 * multiFactor}rem`,
      backgroundRepeat: 'no-repeat',
      borderRadius: `0 0 0 ${0.3125 * multiFactor}rem`,
      content: '""',
      display: 'block',
      height: `${2.875 * multiFactor}rem`,
      position: 'absolute',
      right: `${0.375 * multiFactor}rem`,
      top: `${0.125 * multiFactor}rem`,
      width: `${2.625 * multiFactor}rem`,
      zIndex: 2,
      transform: `rotate(22deg) translateY(${-0.625 * multiFactor}rem) scaleX(.9)`,
      transition: 'transform .2s, background-position .2s,height .2s'
    },

    '&:hover:before': {
      backgroundPosition: 0,
      transform: `rotate(15deg) translate(${-0.5 * multiFactor}rem, ${-0.1875 * multiFactor}rem) scaleX(1)`
    },

    '&:after': {
      background: '#0079a6',
      bottom: 0,
      content: '""',
      display: 'block',
      height: `${0.625 * multiFactor}rem`,
      position: 'absolute',
      right: `${1.5625 * multiFactor}rem`,
      transform: 'skew(40deg, 0)',
      transition: 'right .2s',
      width: `${0.9375 * multiFactor}rem`
    },

    '&:hover:after': {
      right: `${2.375 * multiFactor}rem`
    },

    '&:hover span': {
      width: `${3.125 * multiFactor}rem`
    }
  };
});

const HiddenCode = styled('span')(({ theme }) => {
  return {
    width: `${2.25 * multiFactor}rem`,
    height: `${2.5 * multiFactor}rem`,
    lineHeight: `${2.5 * multiFactor}rem`,
    padding: `0 ${theme.spacing(2)}`,
    boxSizing: 'border-box',
    background: theme.palette.grey[200],
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    color: theme.palette.text.secondary,
    display: 'block',
    overflow: 'hidden',
    position: 'absolute',
    direction: 'rtl',
    right: 0,
    textAlign: 'right',
    top: 0,
    transition: 'width .2s'
  };
});

const CustomButton = (props: ButtonUnstyledProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
};

const PeelButton = ({ url, onClickCallback }: { url: string; onClickCallback: () => void }) => {
  return (
    <CustomButton href={url} onClick={onClickCallback}>
      Get Code
      <HiddenCode>CODE1234...</HiddenCode>
    </CustomButton>
  );
};

export default PeelButton;
