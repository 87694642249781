import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import OfferStrip from '../../components/OfferStrip/OfferStrip';
import { Campaign, Offer } from '../../services/Interfaces';
import { getCampaigns, getFeaturedOffers } from '../../services/api';
import { FEATURED_LOCATION_LARGE_TOP, FEATURED_LOCATION_SMALL_TOP } from '../../configurations/constants';
import { getDefaultMetaTitle } from '../../services/utilities';
import OfferCarousel from '../../components/OfferCarousel/OfferCarousel';

const Homepage = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [topOffers, setTopOffers] = useState<Offer[]>([]);
  const [section1, setSection1] = useState<Offer[]>([]);
  // const [section2, setSection2] = useState<Offer[]>([]);

  useEffect(() => {
    getCampaigns().then(setCampaigns);
    getFeaturedOffers(FEATURED_LOCATION_LARGE_TOP).then(setTopOffers);
    getFeaturedOffers(FEATURED_LOCATION_SMALL_TOP, 8).then(setSection1);
    // getOffersByCategory('Clothing', 8).then(setSection1);
    // getOffersByCategory('Clothing', 8).then(setSection2);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{getDefaultMetaTitle()}</title>
      </Helmet>
      <Container>
        <OfferCarousel campaigns={campaigns} />
        <OfferStrip stripTitle="Top Offers" items={topOffers} isLoading={!topOffers.length} previewQuantity={6} />
        <OfferStrip stripTitle="Featured Offers" small items={section1} isLoading={!section1.length} previewQuantity={8} />
        {/* <OfferStrip stripTitle="Clothing" small items={section2} isLoading={!section2.length} previewQuantity={8} /> */}
      </Container>
    </HelmetProvider>
  );
};

export default Homepage;
