import { AppBar, Box, Container } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import MainNav from './MainNav';
import { NAVBAR_HEIGHT } from '../../configurations/constants';
import JoinArea from './JoinArea';
import { ReactComponent as Logo } from '../../assets/images/logo_shade.svg';

const Header = () => {
  const StyledAppBar = styled(AppBar)(({ theme }) => ({
    borderBottomColor: theme.palette.secondary.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    backgroundColor: 'transparent',
    color: 'black',
    zIndex: 3
  }));

  const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0
    }
  }));

  const LogoLink = styled(Link)(({ theme }) => ({
    minHeight: NAVBAR_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      // marginLeft: theme.spacing()
      margin: '0 auto'
    }
  }));

  return (
    <StyledAppBar position="relative" elevation={0}>
      <StyledContainer>
        <Box sx={{ minHeight: NAVBAR_HEIGHT }} />
        <LogoLink to="/">
          <Logo style={{ height: '35px' }} />
        </LogoLink>
        {/* <UserAccountMenu /> */}
        <JoinArea />
        <MainNav />
      </StyledContainer>
    </StyledAppBar>
  );
};

export default Header;
