import { Container, Link, Skeleton, Typography } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/system';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Merchant } from '../../services/Interfaces';
import { getMerchants } from '../../services/api';
import LetterButton from '../../components/LetterButton/LetterButton';
import { getMetaTitle } from '../../services/utilities';
import { getMerchantBusinessTypeBadge } from '../../components/MerchantBusinessTypeBadge/MerchantBusinessTypeBadge';

const BrandDirectory = () => {
  const mainTheme = useTheme();

  const scrollIntoView = (selector?: string) => {
    if (selector) {
      document.querySelector(selector)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const LettersNavigationOrderedList = styled('nav')(({ theme }) => ({
    listStyle: 'none',
    margin: `0 0 ${theme.spacing(4)} 0`,
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }));

  const styledAlphabetNav: CSSProperties = {
    listStyle: 'none',
    margin: 0,
    padding: 0
  };

  const AlphabetListItem = styled('li')(({ theme }) => ({
    display: 'flex',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4)
  }));

  const styledAlphabetNavLetter: CSSProperties = {
    textTransform: 'uppercase',
    width: '3em'
  };

  const StyledBrandsNav = styled('ol')(({ theme }) => ({
    flex: 1,
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'grid',
    gridColumnGap: theme.spacing(2),
    gridTemplateColumns: 'repeat(1, 1fr)',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(5, 1fr)'
    }
  }));

  const numbersSymbol = '#';
  const numberHashValue = 'number';

  const alphabetArray = [...Array(26)].map((value, index) => String.fromCharCode(97 + index));
  alphabetArray.unshift(numbersSymbol);

  const [merchantsMap, setMerchantsMap] = useState<Map<string, Merchant[]>>(new Map(new Map(alphabetArray.map(letter => [letter, []]))));
  const [merchantLoaded, setMerchantLoaded] = useState<boolean>(false);

  const { hash } = useLocation();

  useEffect(() => {
    getMerchants(true).then(merchants => {
      // Prepare alphabet index
      const newMerchantsMap: Map<string, Merchant[]> = new Map(alphabetArray.map(letter => [letter, []]));

      merchants.forEach(merchant => {
        const firstLetter: string = merchant.name?.charAt(0) || '';
        newMerchantsMap.get(Number.isNaN(Number(firstLetter)) ? firstLetter.toLowerCase() : numbersSymbol)?.push(merchant);
      });

      setMerchantLoaded(true);
      setMerchantsMap(newMerchantsMap);
    });
  }, []);

  useEffect(() => {
    scrollIntoView(hash);
  }, [merchantsMap, hash]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{getMetaTitle(`All Brands and Advertisers From A to Z`)}</title>
      </Helmet>
      <Container sx={{ mt: 4, mb: 4 }}>
        <article>
          <Typography component="h1" variant="h3" sx={{ mb: 4 }}>
            All Brands A-Z
          </Typography>
          <nav>
            <LettersNavigationOrderedList>
              {alphabetArray.map(value => {
                const hashValue = value === numbersSymbol ? numberHashValue : value;

                return (
                  <li key={value}>
                    <LetterButton text={value} href={`#${hashValue}`} />
                  </li>
                );
              })}
            </LettersNavigationOrderedList>
          </nav>

          {!merchantLoaded ? (
            [...Array(5)].map((value, index) => (
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  backgroundColor: mainTheme.palette.grey[100],
                  borderRadius: `${mainTheme.shape.borderRadius}px`,
                  height: mainTheme.spacing(15),
                  marginBottom: mainTheme.spacing(2)
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              />
            ))
          ) : (
            <ol style={styledAlphabetNav}>
              {Array.from(merchantsMap.entries()).map(([letter, merchants]) => {
                const displayedLetter = letter === numbersSymbol ? numberHashValue : letter;

                return (
                  <AlphabetListItem key={displayedLetter} id={`${displayedLetter}`}>
                    <Typography variant="h4" component="h2" style={styledAlphabetNavLetter}>
                      {letter}
                    </Typography>
                    <StyledBrandsNav>
                      {merchants.map(merchant => (
                        <li key={merchant.urlSlug}>
                          <Link component={RouterLink} to={`/${merchant.urlSlug}`} color="inherit">
                            {merchant.name}
                          </Link>
                          {getMerchantBusinessTypeBadge(merchant, 'sm')}
                        </li>
                      ))}
                    </StyledBrandsNav>
                  </AlphabetListItem>
                );
              })}
            </ol>
          )}
        </article>
      </Container>
    </HelmetProvider>
  );
};
export default BrandDirectory;
