import { CSSProperties } from 'react';
import { Merchant } from '../../services/Interfaces';
import { LGBTQ_BUSINESS_STATUS } from '../../configurations/constants';

export const MerchantBusinessTypeBadge = ({ text, size }: { text: string; size?: 'md' | 'sm' }) => {
  const defaultStyle: CSSProperties = {
    backgroundColor: '#ff6c00',
    color: 'white',
    fontWeight: 500,
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    borderRadius: '4px',
    padding: '0 0.3em',
    fontSize: '0.7em'
  };

  const smStyles: CSSProperties = {
    fontSize: '0.5em'
  };

  const getStyles = (s?: string) => {
    switch (s) {
      case 'sm':
        return { ...defaultStyle, ...smStyles };
      case 'md':
      default:
        return defaultStyle;
    }
  };

  return <span style={getStyles(size)}>{text}</span>;
};

MerchantBusinessTypeBadge.defaultProps = {
  size: undefined
};

export const getMerchantBusinessTypeBadge = (merchant: Merchant, size?: 'sm' | 'md') => {
  const type = merchant?.lgbtqOwnedBusiness;

  switch (type) {
    case LGBTQ_BUSINESS_STATUS.OWNED:
      return (
        <>
          <span /> <MerchantBusinessTypeBadge text={LGBTQ_BUSINESS_STATUS.OWNED.toString()} size={size} />
        </>
      );
    // case LGBTQ_BUSINESS_STATUS.SUPPORTING:
    //   return (
    //     <>
    //       <span /> <Badge text={LGBTQ_BUSINESS_STATUS.SUPPORTING.toString()} />
    //     </>
    //   );
    default:
      return '';
  }
};
