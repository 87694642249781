import { Container, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getMetaTitle } from '../../services/utilities';

const cookies = [
  { name: 'Cookie Example 1', usage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
  { name: 'Cookie Example 2', usage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
  { name: 'Cookie Example 3', usage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
  { name: 'Cookie Example 4', usage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' }
];

const CookiePolicy = () => {
  const theme = useTheme();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{getMetaTitle(`Cookie Policy`)}</title>
      </Helmet>
      <Container sx={{ mt: 4, mb: 4 }}>
        <article>
          <Typography variant="h1" sx={{ mb: 4 }}>
            Cookie Policy
          </Typography>

          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography variant="h3" component="h2" sx={{ mb: 2, mt: 3 }}>
              Information about our use of cookies
            </Typography>

            <Typography sx={{ mb: 2 }}>
              Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good
              experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are
              agreeing to our use of cookies.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Cookies are small text files that, if you agree, are placed on your browser or the hard drive of your computer by websites
              that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide
              information to the owners of the site.
            </Typography>
          </section>

          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography sx={{ mb: 2 }}>We use the following cookies:</Typography>
            <Typography sx={{ mb: 2 }}>
              <strong>Functional Cookies</strong>: These are cookies that are required for the operation of our website. They include, for
              example, cookies that enable you to log into secure areas of our website and those that personalise your experience on the
              site.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              <strong>Analytical/Performance Cookies</strong>: They allow us to recognise and count the number of visitors and to see how
              visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by
              ensuring that users are finding what they are looking for easily.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              <strong>Targeting Cookies</strong>: These cookies record your visit to our website, the pages you have visited and the links
              you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your
              interests. We may also share this information with third parties for this purpose.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              <strong>Advertising Cookies</strong>: these cookies are used to display tailored advertisements on this website or on those of
              third parties. They may collect a de-identified, non-human readable version of your email address for matching to other data
              about you to improve the relevance of online advertising and may also collect information such as your IP address, browser or
              operating system type.
            </Typography>
            <Typography sx={{ mb: 2 }}>The table below explains the cookies we use and why.</Typography>

            <Table aria-label="Cookie List">
              <TableHead>
                <TableRow>
                  <TableCell>Cookie Name</TableCell>
                  <TableCell>Usage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cookies.map(row => (
                  <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.usage}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </section>

          <Typography sx={{ mb: 2 }}>
            Please note that third parties (including, for example, advertising networks and providers of external services like web traffic
            analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance
            cookies or targeting cookies.
          </Typography>
        </article>

        <article style={{ marginBottom: theme.spacing(4) }}>
          <Typography variant="h3" component="h2" sx={{ mb: 2, mt: 3 }}>
            How do I change my cookie settings?
          </Typography>

          <Typography sx={{ mb: 2 }}>
            Most web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how
            to see what cookies have been set and how to manage and delete them, visit{' '}
            <Link href="https://www.aboutcookies.org">www.aboutcookies.org</Link> or{' '}
            <Link href="https://www.allaboutcookies.org">www.allaboutcookies.org</Link>.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            To opt out of being tracked by Google Analytics across all websites visit{' '}
            <Link href="https://tools.google.com/dlpage/gaoptout">tools.google.com/dlpage/gaoptout</Link>.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies.
            However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all
            or parts of our site.
          </Typography>
        </article>
      </Container>
    </HelmetProvider>
  );
};

export default CookiePolicy;
