import { Container } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getMetaTitle } from '../../services/utilities';

const SignUp = () => (
  <HelmetProvider>
    <Helmet>
      <title>{getMetaTitle(`Sign-up to our Newsletter`)}</title>
    </Helmet>
    <Container sx={{ mt: 4, mb: 4 }}>
      <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js" />
      <iframe
        title="Sign Up Form"
        className="airtable-embed airtable-dynamic-height"
        src="https://airtable.com/embed/shreENpL8kgl2RaUw?backgroundColor=cyan"
        frameBorder="0"
        width="100%"
        height="1300"
        style={{
          background: 'transparent'
        }}
      />
    </Container>
  </HelmetProvider>
);

export default SignUp;
