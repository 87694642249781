import { ThemeProvider } from '@mui/material';
import { Outlet } from 'react-router-dom';
import defaultTheme from '../../configurations/themes/defaultTheme';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ScrollToTop from '../../components/ScrollTop/ScrollTop';

const SiteLayout = () => (
  <ThemeProvider theme={defaultTheme}>
    {/* <div className="skip-to-content-link"> */}
    {/*  <a href="#main">Skip to content</a> */}
    {/* </div> */}
    <ScrollToTop />
    <Header />
    <main
      id="main"
      style={{
        flex: '1 0 auto'
      }}
    >
      <Outlet />
    </main>
    <Footer />
  </ThemeProvider>
);

export default SiteLayout;
