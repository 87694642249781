import { Box, Container, Grid, IconButton, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { FacebookOutlined, Instagram, Twitter } from '@mui/icons-material';
import {
  REGISTERED_COMPANY_ADDRESS,
  REGISTERED_COMPANY_NAME,
  REGISTERED_COMPANY_NUMBER,
  SOCIAL_URL_FB,
  SOCIAL_URL_IG,
  SOCIAL_URL_TW
} from '../../configurations/constants';
import footerLogo from '../../assets/images/logo_secondary.svg';

const Footer = () => {
  const defaultTheme = useTheme();

  const Heading = styled(Typography as any)(({ theme }) => ({
    color: theme.palette.grey[800],
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: theme.spacing()
  }));

  const Link = styled(RouterLink)(({ theme }) => ({
    color: theme.palette.grey[700],
    textDecoration: 'none',
    fontSize: '0.875rem',
    marginBottom: theme.spacing(0.5)
  }));

  return (
    <>
      <Box
        sx={{
          mt: 5,
          [defaultTheme.breakpoints.down('sm')]: {
            textAlign: 'center'
          },
          borderTop: `2px solid ${defaultTheme.palette.secondary.main}`,
          borderBottom: `2px solid ${defaultTheme.palette.secondary.main}`
        }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <img
                src={footerLogo}
                alt="Logo"
                style={{
                  maxWidth: '150px',
                  marginBottom: defaultTheme.spacing(2),
                  marginTop: defaultTheme.spacing(2),
                  verticalAlign: 'middle'
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: defaultTheme.palette.grey[50],
          [defaultTheme.breakpoints.down('sm')]: {
            textAlign: 'center'
          }
        }}
      >
        <Container>
          <Grid container spacing={2} component="nav" sx={{ marginTop: 3, marginBottom: 3 }}>
            <Grid item md={4} sm={6} xs={12} component="section">
              <Heading variant="body2" component="h3">
                About
              </Heading>
              <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </Grid>
            <Grid item md={4} sm={6} xs={12} component="section">
              <Heading variant="body2" component="h3">
                Legal
              </Heading>
              <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                <li>
                  <Link to="/terms-of-service">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/cookie-policy">Cookie Policy</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </Grid>
            <Grid item md={4} sm={6} xs={12} component="section">
              <Heading variant="body2" component="h3">
                Join
              </Heading>
              <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                <li>
                  <Link to="/merchant-signup">Merchant Signup</Link>
                </li>
                <li>
                  <Link to="/sign-up">Subscribe To Our Newsletter</Link>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <IconButton aria-label="Facebook" href={SOCIAL_URL_FB} target="_blank">
                <FacebookOutlined color="secondary" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="Instagram" href={SOCIAL_URL_IG} target="_blank">
                <Instagram color="secondary" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="Twitter" href={SOCIAL_URL_TW} target="_blank">
                <Twitter color="secondary" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginBottom: 3, marginTop: 3 }}>
            <Grid item>
              <Typography variant="caption" sx={{ color: defaultTheme.palette.grey[500] }}>
                © {new Date().getFullYear()} {REGISTERED_COMPANY_NAME}. Company number {REGISTERED_COMPANY_NUMBER}. Registered office{' '}
                {REGISTERED_COMPANY_ADDRESS}.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
