import { Avatar, Button, Card, CardActions, CardContent, Collapse, Link, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { ShoppingBag } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Offer } from '../../services/Interfaces';
import { SHOW_OFFER_EXPIRY_DATE_IN_DAYS } from '../../configurations/constants';
import PeelButton from '../PeelButton/PeelButton';
import { toLocalDate } from '../../services/utilities';
import { getMerchantBusinessTypeBadge } from '../MerchantBusinessTypeBadge/MerchantBusinessTypeBadge';
import { openMerchantSite, OpenMerchantSiteLink } from '../OpenMerchantSiteLink/OpenMerchantSiteLink';

const OfferCard = ({ offer, showMerchant, isSelected }: { offer: Offer; showMerchant?: boolean; isSelected?: boolean }) => {
  const mainTheme = useTheme();
  const gapSize = 2;

  const showEndDate = (date: any) => {
    // Show the expiry date if the offer is expiring within 7 days.
    const daysInMs = SHOW_OFFER_EXPIRY_DATE_IN_DAYS * 1000 * 60 * 60 * 24;
    const offset = +new Date(date) - +new Date();
    return offset <= daysInMs;
  };

  const highlightEndDate = (date: any) => {
    // Highlight if the offer is expiring within 2 days
    const daysInMs = 2 * 1000 * 60 * 60 * 24;
    const offset = +new Date(date) - +new Date();
    return offset <= daysInMs;
  };

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      component="article"
      elevation={3}
      sx={{
        width: '100%',
        mb: 3,
        overflow: 'inherit',
        position: 'relative',
        border: `3px solid ${isSelected ? mainTheme.palette.secondary.main : 'transparent'}`
      }}
      id={offer.id}
    >
      <CardContent
        sx={{
          display: 'flex',
          gap: mainTheme.spacing(gapSize),
          flexWrap: 'wrap',
          padding: '10px',

          '&:last-child': {
            padding: '10px'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: mainTheme.spacing(gapSize),
            [mainTheme.breakpoints.up('sm')]: {
              flex: 1
            }
          }}
        >
          <OpenMerchantSiteLink offer={offer}>
            <Avatar
              alt={offer.merchant.name}
              variant="rounded"
              src={offer.merchant.logo}
              style={{
                width: '100px',
                height: '100px',
                border: `1px solid ${mainTheme.palette.grey[300]}`,
                backgroundColor: mainTheme.palette.grey[100]
                // marginRight: theme.spacing(1.5)
              }}
            >
              <ShoppingBag sx={{ fontSize: '2em' }} />
            </Avatar>
          </OpenMerchantSiteLink>
          <Box sx={{ flex: 1 }}>
            <OpenMerchantSiteLink offer={offer} style={{ color: 'inherit', textDecoration: 'none' }}>
              <Typography component="h3" variant="h5" color="primary">
                {offer.offerFlags?.exclusive && <span style={{ color: mainTheme.palette.error.main }}>Exclusive </span>}
                {offer.title}
              </Typography>
            </OpenMerchantSiteLink>
            {showMerchant ? (
              <>
                <Typography>{offer.merchant.name}</Typography>
                {getMerchantBusinessTypeBadge(offer.merchant)}
              </>
            ) : (
              ''
            )}
            {offer.endDate && showEndDate(offer.endDate) ? (
              <Typography
                variant="subtitle2"
                display="block"
                sx={highlightEndDate(offer.endDate) ? { color: mainTheme.palette.error.main } : {}}
              >
                Ends {toLocalDate(offer.endDate)}
              </Typography>
            ) : (
              ''
            )}
          </Box>
        </Box>
        <Box
          sx={{
            alignSelf: 'flex-start',
            width: '100%',
            [mainTheme.breakpoints.up('sm')]: {
              width: 'auto'
            }
          }}
        >
          <PeelButton url={offer.url || '/'} onClickCallback={() => openMerchantSite(offer)} />
        </Box>
      </CardContent>
      <CardActions disableSpacing sx={{ borderTop: `1px solid ${mainTheme.palette.grey[300]}`, paddingTop: 0, paddingBottom: 0 }}>
        <Button
          disableRipple
          size="small"
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Show Terms & Conditions"
          endIcon={
            <ExpandMoreIcon
              sx={{
                transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)'
              }}
            />
          }
          sx={{
            padding: '2px 0 0',
            color: theme => theme.palette.grey['500'],
            textTransform: 'none',

            '&:hover': {
              backgroundColor: 'inherit'
            }
          }}
        >
          Terms
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          sx={{
            paddingTop: 1,

            '&:last-child': {
              paddingBottom: 1
            }
          }}
        >
          <Typography paragraph variant="body2" sx={{ marginBottom: 1 }}>
            {offer ? offer['ts&cs'] : 'Terms & Conditions Apply'}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

OfferCard.defaultProps = {
  showMerchant: false,
  isSelected: false
};

export default OfferCard;
