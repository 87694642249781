import { Merchant, Offer } from './Interfaces';

export const filterOfferWithValidStartDate = (offer: Offer) => {
  if (offer.startDate) {
    const offset = +new Date() - +new Date(offer.startDate);
    return offset >= 0;
  }
  return true;
};

export const filterOfferWithValidEndDate = (offer: Offer) => {
  if (offer.endDate) {
    const offset = +new Date(offer.endDate) - +new Date();
    return offset >= 0;
  }
  return true;
};

export const filterLiveOffer = (offer: Offer) => offer.live;

export const filterLiveMerchants = (merchant: Merchant) => merchant.live;
