import { Avatar, Box, Card, CardContent, Skeleton, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { Link } from 'react-router-dom';
import { ShoppingBag } from '@mui/icons-material';
import { Offer } from '../../services/Interfaces';
import { FALLBACK_OFFER_ARTWORK } from '../../configurations/constants';

const OfferTitle = styled(Typography)(() => ({
  fontWeight: 500,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

const avatarHeight = 64;
const avatarSmallHeight = 50;

const OfferPreviewCard = ({ offer, small }: { offer?: Offer; small?: boolean }) => {
  const theme = useTheme();

  const offerContent = (
    <Card elevation={3} sx={{ width: '100%' }}>
      <Box
        sx={{
          pb: '50%',
          height: 0,
          backgroundColor: theme.palette.grey[100],
          backgroundImage: !offer ? 'none' : `url('${offer.artwork || FALLBACK_OFFER_ARTWORK}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      />
      <CardContent>
        <>
          {!offer ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{
                width: small ? avatarSmallHeight : avatarHeight,
                height: small ? avatarSmallHeight : avatarHeight,
                marginTop: `${small ? -55 : -avatarHeight}px`,
                backgroundColor: theme.palette.grey[100],
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: `${theme.shape.borderRadius}px`
              }}
            />
          ) : (
            // <OfferAvatar
            //   name={offer.merchant.name || ''}
            //   logo={offer.merchant.logo}
            //   small={small}
            //   style={{
            //     marginTop: `${small ? -55 : -AVATAR_HEIGHT}px`
            //   }}
            // />
            <Avatar
              alt={offer.merchant.name}
              variant="rounded"
              src={offer.merchant.logo}
              style={{ border: `1px solid ${theme.palette.grey[300]}`, backgroundColor: theme.palette.grey[100] }}
              sx={{
                width: small ? avatarSmallHeight : avatarHeight,
                height: small ? avatarSmallHeight : avatarHeight,
                marginTop: `${small ? -55 : -avatarHeight}px`
              }}
            >
              <ShoppingBag sx={{ fontSize: '2em' }} />
            </Avatar>
          )}

          <Typography variant="overline" display="block" noWrap sx={{ mb: -0.5, mt: 0.5 }}>
            {!offer ? <Skeleton variant="text" animation="wave" width="100px" /> : offer.merchant.name}
          </Typography>
          <OfferTitle variant="body1" color="primary" display="inline" sx={{ mb: -1, fontSize: `${small ? 1 : 1.125}rem` }}>
            {!offer ? (
              <>
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
              </>
            ) : (
              <>
                {offer.offerFlags?.exclusive && <span style={{ color: theme.palette.error.main }}>Exclusive </span>}
                {offer.title}
              </>
            )}
          </OfferTitle>
        </>
      </CardContent>
    </Card>
  );

  return offer ? (
    <Link to={`${offer.merchant.urlSlug}?o=${offer.id}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flex: 1 }}>
      {offerContent}
    </Link>
  ) : (
    offerContent
  );
};

OfferPreviewCard.defaultProps = {
  offer: undefined,
  small: false
};

export default OfferPreviewCard;
