export const NAVBAR_HEIGHT = '64px';

export const SITE_PUBLIC_SHORT_NAME = 'Happy Codes';

export const PUBLIC_WEBSITE_URL_SHORT = 'gethappycodes.com';
export const PUBLIC_WEBSITE_URL_FULL = 'https://gethappycodes.com';

export const EMAIL_ENQUIRE = 'enquiries@gethappycodes.com';
export const EMAIL_GDPR = 'happyvcs-gdpr@gmail.com';
export const EMAIL_PRESS = 'press@gethappycodes.com';

export const REGISTERED_COMPANY_ADDRESS = '71-75 Shelton Street, London, WC2H 9JQ';
export const REGISTERED_COMPANY_NAME = 'SoBermo Ltd';
export const REGISTERED_COMPANY_NUMBER = '13381154';

export const FALLBACK_OFFER_ARTWORK = 'https://fakeimg.pl/380x190?retina=1&text=380x190%0APlaceholder&font=Hind%20Siliguri';

export const PARAM_NAME_REVEAL_VOUCHER_CODE = 'or';
export const PARAM_NAME_SELECTED_OFFER = 'o';

export const FEATURED_LOCATION_LARGE_TOP = 'HomePage - Large - TOP';
export const FEATURED_LOCATION_SMALL_TOP = 'HomePage - Small - TOP';

export const SHOW_OFFER_EXPIRY_DATE_IN_DAYS = 7;

export const META_TITLE_PREFIX = '';
export const META_TITLE_POSTFIX = ` - ${SITE_PUBLIC_SHORT_NAME}`;

export const SOCIAL_URL_FB = 'https://www.facebook.com/happy.vouchercodes.9';
export const SOCIAL_URL_TW = 'https://twitter.com/gethappycodes';
export const SOCIAL_URL_IG = 'https://www.instagram.com/gethappycodes/';

// eslint-disable-next-line no-shadow
export enum LGBTQ_BUSINESS_STATUS {
  // eslint-disable-next-line no-unused-vars
  OWNED = 'LGBTQ+ owned',
  // eslint-disable-next-line no-unused-vars
  SUPPORTING = 'LGBTQ+ supporting'
}
