import { Container, Grid, Skeleton } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { MerchantPage, Offer } from '../../services/Interfaces';
import { getMerchantDetails } from '../../services/api';
import MerchantTitle from '../../components/MerchantTitle/MerchantTitle';
import OfferCard from '../../components/OfferCard/OfferCard';
import { PARAM_NAME_SELECTED_OFFER, PARAM_NAME_REVEAL_VOUCHER_CODE } from '../../configurations/constants';
import VoucherCodeOfferModal from '../VoucherCodeOfferModal/VoucherCodeOfferModal';
import BrowseByBrand from '../../components/BrowseByBrand/BrowseByBrand';
import { getDefaultMetaTitle, getMetaTitle } from '../../services/utilities';

const MerchantDetails = () => {
  const { merchantUrlSlug } = useParams();

  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const currentOfferId = urlParams.get(PARAM_NAME_SELECTED_OFFER);
  const currentOfferIdReveal = urlParams.get(PARAM_NAME_REVEAL_VOUCHER_CODE);

  const [merchantPage, setMerchantPage] = useState<MerchantPage>();
  const [selectedOffer, setSelectedOffer] = useState<Offer>();
  const [offerToReveal, setOfferToReveal] = useState<Offer>();

  const theme = useTheme();

  const scrollIntoView = () => {
    if (currentOfferId || currentOfferIdReveal) {
      const currentOfferElem = document.querySelector(`#${currentOfferId || currentOfferIdReveal}`);

      if (currentOfferElem) {
        currentOfferElem.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  };

  useEffect(() => {
    if (merchantUrlSlug) {
      setMerchantPage(undefined);
      getMerchantDetails(merchantUrlSlug).then(setMerchantPage);
    }
  }, [merchantUrlSlug]);

  useEffect(() => {
    if (merchantPage) {
      setSelectedOffer(merchantPage.offers?.find(offer => offer.id === currentOfferId || offer.id === currentOfferIdReveal));
    }
  }, [merchantPage]);

  useEffect(() => {
    if (selectedOffer && currentOfferIdReveal) {
      setOfferToReveal(selectedOffer);
    }
  }, [selectedOffer]);

  useEffect(() => {
    scrollIntoView();
  }, [selectedOffer]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{merchantPage ? getMetaTitle(`${merchantPage.merchant.name} - Discount Codes`) : getDefaultMetaTitle()}</title>
      </Helmet>
      <Container sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ ml: '13px', mr: '13px' }}>
          <MerchantTitle merchant={merchantPage?.merchant} />
        </Box>
        <Grid container columnSpacing={{ xs: 1, sm: 4 }} rowSpacing={2}>
          <Grid item md={8}>
            {merchantPage && merchantPage.offers
              ? merchantPage.offers.map(offer => <OfferCard offer={offer} key={offer.id} isSelected={offer === selectedOffer} />)
              : [...Array(5)].map((item, index) => (
                  <Skeleton
                    variant="rectangular"
                    sx={{ height: '126px', width: '100%', borderRadius: `${theme.shape.borderRadius}px`, mb: 2 }}
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                  />
                ))}
          </Grid>
          <Grid item md={4}>
            <BrowseByBrand />
          </Grid>
        </Grid>
      </Container>
      <VoucherCodeOfferModal offer={offerToReveal} />
    </HelmetProvider>
  );
};

export default MerchantDetails;
