import { Container, Grid, Skeleton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/system';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Category as ICategory, Offer } from '../../services/Interfaces';
import OfferCard from '../../components/OfferCard/OfferCard';
import { getOffersByCategory } from '../../services/api';
import { StoreContext } from '../../DataContext/DataContext';
import CategoryTitle from '../../components/CategoryTitle/CategoryTitle';
import BrowseByBrand from '../../components/BrowseByBrand/BrowseByBrand';
import { getMetaTitle } from '../../services/utilities';

const Category = () => {
  const { categoryUrlSlug } = useParams();
  const [category, setCategory] = useState<ICategory>();
  const [offers, setOffers] = useState<Offer[]>();
  const [pageTitle, setPageTitle] = useState<string>();
  const theme = useTheme();
  const { categories } = useContext(StoreContext);

  const categoryNameLookup = (urlSlug: string) => categories?.find(cat => cat.urlSlug === urlSlug);

  useEffect(() => {
    if (categoryUrlSlug) {
      setCategory(categoryNameLookup(categoryUrlSlug));
    }
  }, [categoryUrlSlug, categories]);

  useEffect(() => {
    if (category) {
      setOffers(undefined);
      getOffersByCategory(category.name).then(setOffers);
    }
  }, [category]);

  useEffect(() => {
    if (categoryUrlSlug === 'sustainable-ethical') {
      setPageTitle('Shop Sustainable and Ethical LGBTQ+ Brands');
    } else if (categoryUrlSlug === 'lgbt-owned-businesses') {
      setPageTitle('Shop LGBTQ+ Owned Businesses From Around the World');
    } else {
      setPageTitle(`${category?.name} - Discount Codes`);
    }
  }, [categoryUrlSlug]);

  // useEffect(() => {
  //   if (categoryUrlSlug === 'lgbt-owned-businesses') {
  //     // Event snippet for Page View - LGBTQ owned businesses conversion page
  //     gtag('event', 'conversion', { send_to: 'AW-389918509/beANCMCvjcADEK3e9rkB' });
  //   }
  // }, [categoryUrlSlug]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{getMetaTitle(pageTitle)}</title>
      </Helmet>
      <Container sx={{ mt: 4, mb: 4 }}>
        <CategoryTitle category={category} />
        <Grid container columnSpacing={{ xs: 1, sm: 4 }} rowSpacing={2}>
          <Grid item md={8}>
            {offers
              ? offers.map(offer => <OfferCard offer={offer} key={offer.id} showMerchant />)
              : [...Array(5)].map((item, index) => (
                  <Skeleton
                    variant="rectangular"
                    sx={{ height: '126px', width: '100%', borderRadius: `${theme.shape.borderRadius}px`, mb: 2 }}
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                  />
                ))}
          </Grid>
          <Grid item md={4}>
            <BrowseByBrand />
          </Grid>
        </Grid>
      </Container>
    </HelmetProvider>
  );
};

export default Category;
