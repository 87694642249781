import { Button, ClickAwayListener, Container, Link, Paper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ForestTwoToneIcon from '@mui/icons-material/ForestTwoTone';
import { useContext, useState } from 'react';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { StoreContext } from '../../DataContext/DataContext';
import { getMerchantBusinessTypeBadge } from '../MerchantBusinessTypeBadge/MerchantBusinessTypeBadge';

const MainNav = () => {
  const StyledNav = styled('nav')(({ theme }) => ({
    marginLeft: 'auto',

    // Auto scroll on mobile
    overflowX: 'auto',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      order: 1
    }
  }));

  const FirstLevelMenuList = styled('ul')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    listStyle: 'none',
    margin: 0,
    padding: 0
  }));

  const FirstLevelMenuItem = styled('li')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
      // flexGrow: 1
    }
  }));

  const SecondLevelMenu = styled(Paper)(() => ({
    left: 0,
    right: 0,
    top: 'calc(100% + 1px)',
    position: 'absolute',
    zIndex: 2,
    boxShadow: '0px 7px 7px -3px rgba(0,0,0,0.2)',
    whiteSpace: 'normal',
    textAlign: 'left'
  }));

  const SecondLevelMenuList = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    columnFill: 'balance',
    columnCount: 2,
    [theme.breakpoints.up('sm')]: {
      columnCount: 3
    },
    [theme.breakpoints.up('md')]: {
      columnCount: 4
    },
    [theme.breakpoints.up('lg')]: {
      columnCount: 5
    }
  }));

  const [isTrendingOpen, setTrendingOpen] = useState<boolean>(false);
  const [isCategoryOpen, setCategoryOpen] = useState<boolean>(false);
  const { categories, trendingMerchants } = useContext(StoreContext);

  const onClickAway = () => {
    setTrendingOpen(false);
    setCategoryOpen(false);
  };

  const handleMenuClick = (id: string) => {
    if (id === 'trending') {
      setCategoryOpen(false);
      setTrendingOpen(prev => !prev);
    }

    if (id === 'category') {
      setTrendingOpen(false);
      setCategoryOpen(prev => !prev);
    }
  };

  const StyledMenuItemLinkFirstLevel = styled(Button)(({ theme }) => ({
    '&': {
      paddingRight: 0,
      paddingLeft: 0,
      marginLeft: theme.spacing(),
      marginRight: theme.spacing()
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.MuiButton-textInherit': {
      '&:hover, &.active': {
        '&:after': {
          content: '""',
          width: '100%',
          height: '3px',
          backgroundColor: theme.palette.text.primary,
          position: 'absolute',
          bottom: 0
        }
      }
    },
    '&.MuiButton-textPrimary': {
      '&:hover, &.active': {
        '&:after': {
          content: '""',
          width: '100%',
          height: '3px',
          backgroundColor: theme.palette.primary.main,
          position: 'absolute',
          bottom: 0
        }
      }
    },
    '&.MuiButton-textSecondary': {
      '&:hover, &.active': {
        '&:after': {
          content: '""',
          width: '100%',
          height: '3px',
          backgroundColor: theme.palette.secondary.main,
          position: 'absolute',
          bottom: 0
        }
      }
    }
  }));

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <StyledNav>
        <FirstLevelMenuList>
          <FirstLevelMenuItem>
            <StyledMenuItemLinkFirstLevel
              variant="text"
              color="secondary"
              endIcon={<ForestTwoToneIcon sx={{ marginLeft: '4px' }} />}
              href="/category/sustainable-ethical"
            >
              Sustainable
            </StyledMenuItemLinkFirstLevel>
          </FirstLevelMenuItem>
          <FirstLevelMenuItem>
            <StyledMenuItemLinkFirstLevel
              variant="text"
              color="inherit"
              endIcon={<KeyboardArrowDownIcon />}
              onClick={() => handleMenuClick('trending')}
              className={isTrendingOpen ? 'active' : undefined}
            >
              Trending
            </StyledMenuItemLinkFirstLevel>
            <SecondLevelMenu square elevation={0} hidden={!isTrendingOpen}>
              <Container sx={{ height: '100%', mt: 4, mb: 4 }}>
                <SecondLevelMenuList>
                  {trendingMerchants?.map(merchant => (
                    <li key={merchant.urlSlug}>
                      <Link component={RouterLink} to={`/${merchant.urlSlug}`} onClick={() => setTrendingOpen(false)} color="inherit">
                        {merchant.name}
                      </Link>
                      {getMerchantBusinessTypeBadge(merchant, 'sm')}
                    </li>
                  ))}
                </SecondLevelMenuList>
                <Link
                  component={RouterLink}
                  to="/brands"
                  onClick={() => setTrendingOpen(false)}
                  sx={{
                    marginTop: 2,
                    fontWeight: 600,
                    display: 'inline-block'
                  }}
                >
                  Browse All Brands
                </Link>
              </Container>
            </SecondLevelMenu>
          </FirstLevelMenuItem>
          <FirstLevelMenuItem>
            <StyledMenuItemLinkFirstLevel variant="text" color="inherit" href="/category/lgbt-owned-businesses">
              LGBTQ+ Owned
            </StyledMenuItemLinkFirstLevel>
          </FirstLevelMenuItem>
          <FirstLevelMenuItem>
            <StyledMenuItemLinkFirstLevel
              variant="text"
              color="inherit"
              endIcon={<KeyboardArrowDownIcon />}
              onClick={() => handleMenuClick('category')}
              className={isCategoryOpen ? 'active' : undefined}
            >
              Categories
            </StyledMenuItemLinkFirstLevel>
            <SecondLevelMenu square elevation={0} hidden={!isCategoryOpen}>
              <Container sx={{ height: '100%', mt: 4, mb: 4 }}>
                <SecondLevelMenuList>
                  {categories?.map(cat => (
                    <li key={cat.urlSlug}>
                      <Link component={RouterLink} to={`/category/${cat.urlSlug}`} onClick={() => setCategoryOpen(false)} color="inherit">
                        {cat.name}
                      </Link>
                    </li>
                  ))}
                </SecondLevelMenuList>
              </Container>
              {/* <Container sx={{ height: '100%', mt: 4, mb: 4, display: 'flex', flexWrap: 'wrap' }}> */}
              {/*  <Grid container columnSpacing={{ xs: 1, sm: 1 }} rowSpacing={2}> */}
              {/*    <Grid item md={3} sm={4} xs={6} container flexDirection="column"> */}
              {/*      <Link display="block" href="/"> */}
              {/*        Category Group 1 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 1 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 2 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 3 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 4 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 5 */}
              {/*      </Link> */}
              {/*    </Grid> */}
              {/*    <Grid item md={3} sm={4} xs={6} container flexDirection="column"> */}
              {/*      <Link display="block" href="/"> */}
              {/*        Category Group 2 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 1 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 2 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 3 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 4 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 5 */}
              {/*      </Link> */}
              {/*    </Grid> */}
              {/*    <Grid item md={3} sm={4} xs={6} container flexDirection="column"> */}
              {/*      <Link display="block" href="/"> */}
              {/*        Category Group 3 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 1 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 2 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 3 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 4 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 5 */}
              {/*      </Link> */}
              {/*    </Grid> */}
              {/*    <Grid item md={3} sm={4} xs={6} container flexDirection="column"> */}
              {/*      <Link display="block" href="/"> */}
              {/*        Category Group 4 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 1 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 2 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 3 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 4 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 5 */}
              {/*      </Link> */}
              {/*    </Grid> */}
              {/*    <Grid item md={3} sm={4} xs={6} container flexDirection="column"> */}
              {/*      <Link display="block" href="/"> */}
              {/*        Category Group 1 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 1 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 2 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 3 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 4 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 5 */}
              {/*      </Link> */}
              {/*    </Grid> */}
              {/*    <Grid item md={3} sm={4} xs={6} container flexDirection="column"> */}
              {/*      <Link display="block" href="/"> */}
              {/*        Category Group 5 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 1 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 2 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 3 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 4 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 5 */}
              {/*      </Link> */}
              {/*    </Grid> */}
              {/*    <Grid item md={3} sm={4} xs={6} container flexDirection="column"> */}
              {/*      <Link display="block" href="/"> */}
              {/*        Category Group 6 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 1 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 2 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 3 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 4 */}
              {/*      </Link> */}
              {/*      <Link color="palette.primary.main" display="block" href="/"> */}
              {/*        Category 5 */}
              {/*      </Link> */}
              {/*    </Grid> */}
              {/*  </Grid> */}
              {/* </Container> */}
            </SecondLevelMenu>
          </FirstLevelMenuItem>
        </FirstLevelMenuList>
      </StyledNav>
    </ClickAwayListener>
  );
};

export default MainNav;
