// eslint-disable-next-line import/prefer-default-export
import { META_TITLE_POSTFIX, META_TITLE_PREFIX, SITE_PUBLIC_SHORT_NAME } from '../configurations/constants';

export const toKebabCase = (str: string) =>
  str &&
  // @ts-ignore
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');

export const toLocalDate = (date: string | Date) => {
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: undefined, month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

export const getDefaultMetaTitle = () => `${SITE_PUBLIC_SHORT_NAME} - The LGBTQ+ discount code and voucher site`;

export const getMetaTitle = (title?: string) => `${META_TITLE_PREFIX}${title}${META_TITLE_POSTFIX}`;
