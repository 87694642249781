import { Button, CardMedia, Grid, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Carousel, { CarouselRenderControl, CarouselSlideRenderControlProps } from 'nuka-carousel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/system';
import { CSSProperties } from 'react';
import { FALLBACK_OFFER_ARTWORK } from '../../configurations/constants';
import { Campaign } from '../../services/Interfaces';

import './OfferCarousel.scss';

const NavButtonStyles: CSSProperties = {
  border: 'none',
  background: 'transparent',
  padding: 0,
  margin: 0,
  cursor: 'pointer'
};

const PrevButton: CarouselRenderControl = (props: CarouselSlideRenderControlProps) => {
  const { previousSlide } = props;

  return (
    <button type="button" onClick={previousSlide} aria-label="previous" style={{ ...NavButtonStyles }}>
      <ArrowBackIosNewIcon sx={{ fill: 'white' }} />
    </button>
  );
};

const NextButton: CarouselRenderControl = (props: CarouselSlideRenderControlProps) => {
  const { nextSlide } = props;

  return (
    <button type="button" onClick={nextSlide} aria-label="next" style={{ ...NavButtonStyles }}>
      <ArrowForwardIosIcon sx={{ fill: 'white' }} />
    </button>
  );
};

const OfferCarousel = ({ campaigns }: { campaigns?: Campaign[] }) => {
  const defaultTheme = useTheme();

  const defaultControlsConfig = {
    pagingDotsContainerClassName: 'paging-dots-container',
    pagingDotsStyle: {
      fill: defaultTheme.palette.primary.main
    }
  };

  const smallScreen = useMediaQuery(defaultTheme.breakpoints.down('md'));

  return !campaigns?.length ? (
    <Skeleton variant="rectangular" animation="wave" />
  ) : (
    <Box
      sx={{
        backgroundColor: defaultTheme.palette.primary.main,
        color: 'white'
      }}
    >
      <Carousel
        wrapAround
        defaultControlsConfig={defaultControlsConfig}
        renderCenterLeftControls={PrevButton}
        renderCenterRightControls={NextButton}
        autoplay
        autoplayInterval={5000}
      >
        {campaigns.map(campaign => (
          <Grid container key={campaign.id} direction={smallScreen ? 'column-reverse' : undefined}>
            <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex' }}>
              <Box
                sx={{
                  padding: `${defaultTheme.spacing(smallScreen ? 3 : 4)}`,
                  boxSizing: 'border-box',
                  flex: 1,
                  alignSelf: 'center'
                }}
              >
                {campaign.headline ? (
                  <Typography variant="h4" sx={{ textTransform: 'uppercase', fontWeight: 'normal', marginBottom: 1 }}>
                    {campaign.headline}
                  </Typography>
                ) : (
                  ''
                )}
                {campaign.longText ? <Typography sx={{ marginBottom: 1 }}>{campaign.longText}</Typography> : ''}
                {campaign.cta ? (
                  <Button variant="cta" sx={{ width: smallScreen ? '100%' : undefined, marginTop: 2 }} href={campaign.link}>
                    {campaign.cta}
                  </Button>
                ) : (
                  ''
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <CardMedia
                component="img"
                height={smallScreen ? 200 : 300}
                image={campaign.image || FALLBACK_OFFER_ARTWORK}
                alt={campaign.headline}
              />
            </Grid>
          </Grid>
        ))}
      </Carousel>
    </Box>
  );
};

OfferCarousel.defaultProps = {
  campaigns: undefined
};

export default OfferCarousel;
