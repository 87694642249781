import { Container, Typography } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import headImage from '../../assets/images/page-head-about.jpg';
import HeadTitle from '../../components/HeadTitle/HeadTitle';
import { getMetaTitle } from '../../services/utilities';

const About = () => (
  <HelmetProvider>
    <Helmet>
      <title>{getMetaTitle(`About Us`)}</title>
    </Helmet>
    <HeadTitle image={headImage} title="About Us" />
    <Container sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Who we are and what we stand for
      </Typography>
      <Typography sx={{ mb: 1 }}>
        Our mission at Happy Vouchers is to bring the best voucher codes, offers, discounts, cash back rates and deals from LGBTQIA+
        businesses as well as global and local brands to the LGBTQIA+ community.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        If you know of deals and voucher codes that we have not listed, please let us know, so we can share them with other members of the
        LGBTQIA+ community.
      </Typography>
      <Typography>Sharing is caring and makes us all Happy :)</Typography>
    </Container>
  </HelmetProvider>
);

export default About;
