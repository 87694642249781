import { Button, styled } from '@mui/material';
import { ReactNode } from 'react';

const StyledButton = styled(Button)(({ theme }) => ({
  // backgroundColor: 'rgb(17, 48, 245)',
  // background: 'linear-gradient(90deg, rgb(17, 48, 245) 0%, rgb(0, 223, 180) 100%)',
  textTransform: 'none',
  borderRadius: 50,
  // color: 'white',
  minWidth: '100px',
  border: `2px solid ${theme.palette.secondary.main}`,

  '&:hover': {
    border: `2px solid ${theme.palette.secondary.main}`
  }
}));

const JoinButton = ({ text, href, startIcon, endIcon }: { text: string; href: string; startIcon?: ReactNode; endIcon?: ReactNode }) => (
  <StyledButton variant="outlined" color="secondary" href={href} endIcon={endIcon} startIcon={startIcon}>
    {text}
  </StyledButton>
);

JoinButton.defaultProps = {
  startIcon: undefined,
  endIcon: undefined
};

export default JoinButton;
