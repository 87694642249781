import { Campaign, Category, Merchant, MerchantPage, Offer } from './Interfaces';
import offersDump from './data/offers_dump.json';
import categoriesData from './data/categories_dump.json';
import campaignsData from './data/campaigns_dump.json';
import { toKebabCase } from './utilities';
import { filterLiveMerchants, filterLiveOffer, filterOfferWithValidEndDate, filterOfferWithValidStartDate } from './api.utils';

const delay = 300;

export const getMerchantDetails = (urlSlug: string) => {
  const offers: Offer[] = offersDump
    // Filter offers from current merchant
    .filter(offer => offer.merchant.urlSlug === urlSlug)
    // Filter offers with valid dates and live
    .filter(filterOfferWithValidStartDate)
    .filter(filterOfferWithValidEndDate)
    .filter(filterLiveOffer);
  const merchant = offers[0]?.merchant;

  const merchantDetails: MerchantPage = {
    merchant,
    offers
  };

  return new Promise<MerchantPage>(resolve => {
    setTimeout(() => resolve(merchantDetails), delay);
  });
};

export const getMerchantsMap = (liveMerchantsOnly?: boolean) => {
  const merchantsMap: Map<string, { merchant: Merchant; numberOfOffers: number }> = new Map();

  offersDump
    // Filter offers with valid dates and live
    .filter(filterOfferWithValidStartDate)
    .filter(filterOfferWithValidEndDate)
    .filter(filterLiveOffer)
    .filter(offer => (liveMerchantsOnly ? filterLiveMerchants(offer.merchant) : true))
    .forEach(offer => {
      if (offer.merchant) {
        const numberOfOffers: number = merchantsMap.get(offer.merchant.name)?.numberOfOffers || 0;
        merchantsMap.set(offer.merchant.name, {
          merchant: offer.merchant,
          numberOfOffers: numberOfOffers + 1
        });
      }
    });

  return merchantsMap;
};

export const getMerchants = (liveMerchantsOnly?: boolean) => {
  const mockedMerchants: Merchant[] = Array.from(getMerchantsMap(liveMerchantsOnly).entries())
    .sort((a, b) => {
      // Dumb sorting
      const merchant1 = a[1].merchant.name?.toUpperCase();
      const merchant2 = b[1].merchant.name?.toUpperCase();

      if (merchant1 && merchant2 && merchant1 < merchant2) {
        return -1;
      }

      if (merchant1 && merchant2 && merchant1 > merchant2) {
        return 1;
      }

      return 0;
    })
    .map(value => value[1].merchant);

  return new Promise<Merchant[]>(resolve => {
    setTimeout(() => resolve(mockedMerchants), delay);
  });
};

export const getCategories = () => {
  const categories: Category[] = [];

  categoriesData.forEach(cat => categories.push({ name: cat, urlSlug: toKebabCase(cat) }));

  categories.sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });

  return new Promise<Category[]>(resolve => {
    setTimeout(() => resolve(categories), delay);
  });
};

export const getFeaturedOffers = (featuredLocation: string, limit = 6) => {
  const mockedOffers: Offer[] = offersDump
    .filter(offer => {
      return offer.featuredLocation && offer.featuredLocation?.findIndex((fl: string) => fl === featuredLocation) !== -1;
    })
    .sort((a, b) => (a.featuredLocationOrder && b.featuredLocationOrder ? a.featuredLocationOrder - b.featuredLocationOrder : 0))
    .slice(0, limit);

  return new Promise<Offer[]>(resolve => {
    setTimeout(() => resolve(mockedOffers), delay);
  });
};

export const getOffersByCategory = (category: string, limit?: number) => {
  let mockedOffers: Offer[] = offersDump
    // Filter offers from current category only
    .filter(offer => {
      return offer.categories?.filter((cat: string) => cat === category).length;
    })
    // Filter offers with valid dates
    .filter(filterOfferWithValidStartDate)
    .filter(filterOfferWithValidEndDate);

  // Order offers by "category order"
  mockedOffers.sort((a, b) => {
    return (a.categoryOrder || Infinity) - (b.categoryOrder || Infinity);
  });

  if (limit) {
    mockedOffers = mockedOffers.slice(0, limit);
  }

  return new Promise<Offer[]>(resolve => {
    setTimeout(() => resolve(mockedOffers), delay);
  });
};

export const getTrendingMerchants = () => {
  const mockedMerchants: Merchant[] = Array.from(getMerchantsMap(true).entries())
    .sort((a, b) => a[1].numberOfOffers + b[1].numberOfOffers)
    .map(value => value[1].merchant)
    .slice(0, 40);

  return new Promise<Merchant[]>(resolve => {
    setTimeout(() => resolve(mockedMerchants), delay);
  });
};

export const getCampaigns = () => {
  const campaigns: Campaign[] = campaignsData;

  return new Promise<Campaign[]>(resolve => {
    setTimeout(() => resolve(campaigns), delay);
  });
};
