import { Container, Typography } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  EMAIL_ENQUIRE,
  PUBLIC_WEBSITE_URL_SHORT,
  REGISTERED_COMPANY_ADDRESS,
  REGISTERED_COMPANY_NAME,
  REGISTERED_COMPANY_NUMBER,
  SITE_PUBLIC_SHORT_NAME
} from '../../configurations/constants';
import { getMetaTitle } from '../../services/utilities';

const TermsOfService = () => (
  <HelmetProvider>
    <Helmet>
      <title>{getMetaTitle(`Terms and Conditions - Terms of Service`)}</title>
    </Helmet>
    <Container sx={{ mt: 4, mb: 4 }}>
      <article>
        <Typography variant="h1" sx={{ mb: 4 }}>
          Terms of Service
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Please read this document carefully. It contains important information about your rights and obligations. It also contains a
          dispute resolution clause.
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Welcome to {SITE_PUBLIC_SHORT_NAME}. We are glad you have chosen to use our website and/or application. We are committed to
          providing the best possible value and convenience for you. Before you get started, you must read and agree to these Terms of
          Service. Thank you.
        </Typography>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Acceptance of Terms of Service
          </Typography>
          <Typography sx={{ mb: 2 }}>
            We are {SITE_PUBLIC_SHORT_NAME}, a trading name of {REGISTERED_COMPANY_NAME}. Company number {REGISTERED_COMPANY_NUMBER}.
            Registered office {REGISTERED_COMPANY_ADDRESS}.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            By downloading and using {SITE_PUBLIC_SHORT_NAME} (&quot;{SITE_PUBLIC_SHORT_NAME}&quot;, &quot;we&quot; or &quot;us&quot;), you
            accept and agree to be bound by these Terms of Service. {SITE_PUBLIC_SHORT_NAME} may at any time modify these Terms of Service.
            You can review the most current version of it by clicking on the &quot;Terms of Service&quot; link located at the bottom of the{' '}
            {SITE_PUBLIC_SHORT_NAME} website, HappyVouchercodes.co.uk. The most current version will supersede all previous versions. By
            continuing to use the {SITE_PUBLIC_SHORT_NAME}
            application after changes are made and notified to you (on the mobile number you have registered with us), you agree to be bound
            by such changes.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Privacy
          </Typography>
          <Typography sx={{ mb: 2 }}>
            We only use any personal information that you submit or we collect when you are using the {SITE_PUBLIC_SHORT_NAME} application
            in the ways set out in our is subject to the {SITE_PUBLIC_SHORT_NAME} Privacy Policy.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Limitations on Use
          </Typography>
          <Typography sx={{ mb: 2 }}>
            You must be at least 13 years old to download and use the {SITE_PUBLIC_SHORT_NAME} application, or, if you are not at least 13,
            you may download and/or use the {SITE_PUBLIC_SHORT_NAME} application only in conjunction with, and under the supervision of,
            your parent or guardian. If you do not qualify, please do not download the {SITE_PUBLIC_SHORT_NAME} application.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Intellectual Property
          </Typography>
          <Typography sx={{ mb: 2 }}>
            {SITE_PUBLIC_SHORT_NAME} and/or its licensors are the sole owners of the {SITE_PUBLIC_SHORT_NAME} application, which includes
            any software, domains, and content made available through it. The {SITE_PUBLIC_SHORT_NAME} application is protected by United
            Kingdom and International copyright and other intellectual property laws. It is for your own personal and non-commercial use
            only, and {SITE_PUBLIC_SHORT_NAME} grants you a limited license for that purpose.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Without limitation, this means that you may not sell, export, license, modify, copy, reverse engineer, distribute or transmit
            the
            {SITE_PUBLIC_SHORT_NAME} application without {SITE_PUBLIC_SHORT_NAME}&apos;s prior express written permission. Any unauthorized
            use of the Happy Vouchercodes application will terminate the limited license granted by us. {SITE_PUBLIC_SHORT_NAME} and its
            graphics, logos, icons and service names related to the {SITE_PUBLIC_SHORT_NAME} application are registered and unregistered
            trademarks or trade dress of Invitation Digital Ltd. They may not be used without {SITE_PUBLIC_SHORT_NAME}&apos;s prior express
            written permission. All other trademarks not owned by {SITE_PUBLIC_SHORT_NAME} that appear in connection with the{' '}
            {SITE_PUBLIC_SHORT_NAME} application are the property of their respective owners, who may or may not be affiliated with,
            connected to or sponsored by {SITE_PUBLIC_SHORT_NAME}.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Your Conduct
          </Typography>
          <Typography sx={{ mb: 2 }}>
            By downloading and using the {SITE_PUBLIC_SHORT_NAME} application, you agree not to upload, post, e-mail or otherwise send or
            transmit any material that contains viruses or any other computer code, files or programs designed to interrupt, destroy or
            limit the functionality of any computer software or hardware or telecommunications equipment associated with the{' '}
            {SITE_PUBLIC_SHORT_NAME} application. You also agree not to interfere with the servers or networks connected to the{' '}
            {SITE_PUBLIC_SHORT_NAME} application or to violate any of the procedures, policies or regulations of networks connected to the{' '}
            {SITE_PUBLIC_SHORT_NAME} application, the terms of which are incorporated herein. You also agree not to: (1) impersonate any
            other person while using the {SITE_PUBLIC_SHORT_NAME} application; (2) conduct yourself in a vulgar, offensive, harassing or
            objectionable manner while using the {SITE_PUBLIC_SHORT_NAME} application; (3) use the Happy Vouchercodes application for any
            unlawful purpose; or (4) resell or export the software associated with the {SITE_PUBLIC_SHORT_NAME}
            application.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            {SITE_PUBLIC_SHORT_NAME} does not promote, recommend or condone use of the {SITE_PUBLIC_SHORT_NAME} application during certain
            activities, such as automobile driving, where there is a significant risk of accident. You agree not to use the{' '}
            {SITE_PUBLIC_SHORT_NAME} application during such activities.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Third Party Offers
          </Typography>
          <Typography sx={{ mb: 2 }}>
            The {SITE_PUBLIC_SHORT_NAME} application may contain links to third party web sites or programs that are not controlled by or
            affiliated with {SITE_PUBLIC_SHORT_NAME}. {SITE_PUBLIC_SHORT_NAME} is not responsible for the content, offers or privacy
            policies of such sites and programs, including, without limitation, your redemption of such offers or a merchant&apos;s refusal
            to honour any offer. Your dealings with third party sites are solely between you and the applicable third party.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Charges for Using the {SITE_PUBLIC_SHORT_NAME} application
          </Typography>
          <Typography sx={{ mb: 2 }}>
            {SITE_PUBLIC_SHORT_NAME} currently provides the {SITE_PUBLIC_SHORT_NAME} application to you free of charge. However, when you
            use the Happy Vouchercodes application, you may be subject to charges imposed by your wireless or other applicable carrier.
            Payment of such charges is solely your responsibility. The {SITE_PUBLIC_SHORT_NAME} application may not be accessible in some
            foreign countries, this will depend on the {SITE_PUBLIC_SHORT_NAME} application being supported by the foreign network. You
            should check with the local carrier whether you will be required to pay additional charges for such access. The ability to
            download or access the {SITE_PUBLIC_SHORT_NAME} application may be restricted or impaired when on a roaming network abroad.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Use of Information Submitted
          </Typography>
          <Typography sx={{ mb: 2 }}>
            You agree that {SITE_PUBLIC_SHORT_NAME} is free to use any comments, information or ideas contained in any communication you may
            send to
            {SITE_PUBLIC_SHORT_NAME} without compensation, acknowledgement or payment to you for any purpose whatsoever, including, but not
            limited to, developing, manufacturing and marketing products and services and creating, modifying or improving the{' '}
            {SITE_PUBLIC_SHORT_NAME} application or other products or services.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            No Warranty & Liability Limit
          </Typography>
          <Typography sx={{ mb: 2 }}>
            {SITE_PUBLIC_SHORT_NAME} provides the {SITE_PUBLIC_SHORT_NAME} application &quot;as is&quot; and without any warranty or
            condition, whether express, implied or statutory. {SITE_PUBLIC_SHORT_NAME} specifically disclaims any implied warranties of
            title, merchantability, fitness for a particular purpose and non-infringement. {SITE_PUBLIC_SHORT_NAME} assumes no liability or
            responsibility for any errors or omissions in the {SITE_PUBLIC_SHORT_NAME} application; any failures, delays or interruptions in
            the {SITE_PUBLIC_SHORT_NAME} application; any losses or damages arising from the use of the {SITE_PUBLIC_SHORT_NAME}{' '}
            application, including, without limitation, any damage to your mobile device; or any conduct by users of the{' '}
            {SITE_PUBLIC_SHORT_NAME} application. We reserve the right to deliver the {SITE_PUBLIC_SHORT_NAME} application in our sole and
            absolute discretion.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            In no event shall {SITE_PUBLIC_SHORT_NAME}, its shareholders, directors, officers, employees or agents be liable (jointly or
            severally) to you for loss of use or any special, incidental, indirect or consequential damages arising out of or in connection
            with the Happy Vouchercodes application or these terms of service, on any theory of liability, and whether or not advised of the
            possibility of damage. If any applicable authority holds any portion of this section to be unenforceable, then liability will be
            limited to the fullest possible extent permitted by applicable law.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Indemnity
          </Typography>
          <Typography sx={{ mb: 2 }}>
            You agree to indemnify and hold {SITE_PUBLIC_SHORT_NAME} and its related companies, and each of their respective shareholders,
            directors, officers, employees, agents and merchant partners harmless from and against any third-party claim or cause of action,
            including reasonable attorneys&apos; fees and court costs, arising, directly or indirectly, out of your use of the{' '}
            {SITE_PUBLIC_SHORT_NAME}
            application or your violation of any law or the rights of any third party.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Disputes
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Governing Law You agree that these terms of service or any claim, dispute or controversy (whether in contract, tort or
            otherwise, whether preexisting, present or future, and including statutory, common law and equitable claims) between you and{' '}
            {SITE_PUBLIC_SHORT_NAME}
            arising from or relating to these Terms of Service, their interpretation or breach, termination or validity, the relationships
            which result from these Terms of Service, the {SITE_PUBLIC_SHORT_NAME} application, {SITE_PUBLIC_SHORT_NAME}&apos;s advertising
            or any related transaction shall be governed by and construed in accordance with the laws of the United Kingdom.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            General
          </Typography>
          <Typography sx={{ mb: 2 }}>
            If any provision of these Terms of Service is held to be invalid or unenforceable, such provision shall be struck, and the
            remaining provisions shall be enforced. Headings are for reference purposes only and in no way define, limit, construe or
            describe the extent or scope of such section. Our failure to enforce any provision of these Terms of Service shall not
            constitute a waiver of that or any other provision. These Terms of Service set forth the entire understanding and agreement
            between you and {SITE_PUBLIC_SHORT_NAME} with respect to the subject matter hereof.
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
            Contact Us
          </Typography>
          <Typography sx={{ mb: 2 }}>
            If you have any questions about these Terms of Service of {PUBLIC_WEBSITE_URL_SHORT}, please contact us at: {EMAIL_ENQUIRE}
          </Typography>
        </section>
      </article>
    </Container>
  </HelmetProvider>
);

export default TermsOfService;
