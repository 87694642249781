import { Card, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import LetterButton from '../LetterButton/LetterButton';

const BrowseByBrand = () => {
  const alphabetArray = [...Array(26)].map((value, index) => String.fromCharCode(97 + index));

  return (
    <Card elevation={3}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Browse by Brand
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: 2 }}>
          {alphabetArray.map(letter => (
            <LetterButton text={letter} href={`/brands#${letter}`} key={letter} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default BrowseByBrand;
