import { Card, Container, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeadTitle from '../../components/HeadTitle/HeadTitle';
import image from '../../assets/images/page-head-contact-us.jpg';
import { EMAIL_PRESS, SITE_PUBLIC_SHORT_NAME } from '../../configurations/constants';
import { getMetaTitle } from '../../services/utilities';

const ContactUs = () => (
  <HelmetProvider>
    <Helmet>
      <title>{getMetaTitle(`Contact Us`)}</title>
    </Helmet>
    <HeadTitle title="Contact Us" image={image} />
    <Container sx={{ mt: 4, mb: 4 }}>
      <Grid container columnSpacing={{ xs: 1, sm: 4 }} rowSpacing={2}>
        <Grid item sm={12} md={6}>
          <Typography variant="h3" component="h2" sx={{ mb: 2 }}>
            Why not get in touch?
          </Typography>
          <Typography variant="h6" sx={{ mb: 1 }}>
            General Questions and Feedback
          </Typography>
          <Typography sx={{ mb: 1 }}>
            We always like to hear from people interested in {SITE_PUBLIC_SHORT_NAME}. If you&apos;d like to get in touch with questions or
            feedback please use the form. We love hearing from our users, and any suggestions are welcomed.
          </Typography>
          <Typography sx={{ mb: 3 }}>
            To speak to us about promoting your business on {SITE_PUBLIC_SHORT_NAME} please include your company name and phone number and a
            member of our sales team will be happy to call you back to discuss.
          </Typography>

          <Typography variant="h6" sx={{ mb: 1 }}>
            Press Enquiries
          </Typography>
          <Typography sx={{ mb: 1 }}>
            For all press enquiries please get in touch with out team at <Link href={`mailto:${EMAIL_PRESS}`}>press@gethappycodes.com</Link>
            .
          </Typography>
          <Typography sx={{ mb: 3 }}>We look forward to hearing from you...</Typography>

          <Typography variant="h6" sx={{ mb: 1 }}>
            Merchant Sign Up
          </Typography>
          <Typography>
            Sign Up as a Merchant using{' '}
            <Link to="/merchant-signup" component={RouterLink}>
              this form
            </Link>
            .
          </Typography>
        </Grid>
        <Grid sm={12} item md={6}>
          <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js" />
          <Card elevation={3}>
            <iframe
              title="Contact Form"
              className="airtable-embed airtable-dynamic-height"
              src="https://airtable.com/embed/shronwGuodtgpMHJu?backgroundColor=cyan"
              frameBorder="0"
              width="100%"
              height="1200"
              style={{ background: 'transparent' }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  </HelmetProvider>
);

export default ContactUs;
