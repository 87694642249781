import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getMetaTitle } from '../../services/utilities';

const MerchantSignup = () => {
  // const theme = useTheme();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{getMetaTitle(`Merchant Sign-up - Register as a Brand and Advertiser`)}</title>
      </Helmet>
      <Container sx={{ mt: 4, mb: 4 }}>
        <article>
          {/* <Typography variant="h1" sx={{ mb: 4 }}> */}
          {/*  Merchant Signup */}
          {/* </Typography> */}
          <Box>
            <iframe
              title="Merchant Signup Form"
              className="airtable-embed"
              src="https://airtable.com/embed/shraM5jwplUj7UeNe?backgroundColor=cyan"
              frameBorder="0"
              width="100%"
              height="3000px"
              // style="background: transparent; border: 1px solid #ccc;"
            />
          </Box>
        </article>
      </Container>
    </HelmetProvider>
  );
};

export default MerchantSignup;
