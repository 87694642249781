import { styled } from '@mui/system';
import { Box } from '@mui/material';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import JoinButton from '../JoinButton/JoinButton';
import { NAVBAR_HEIGHT } from '../../configurations/constants';

const MenuIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  order: 1,
  minHeight: NAVBAR_HEIGHT,
  marginLeft: theme.spacing(),
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto'
  },
  [theme.breakpoints.down('sm')]: {
    // position: 'absolute',
    // top: 0,
    // right: theme.spacing()
    order: 3,
    margin: `0 auto`
    // width: '100%'
  }
}));

const JoinArea = () => (
  <MenuIconContainer>
    <JoinButton text="Subscribe" href="/sign-up" startIcon={<AlternateEmailTwoToneIcon sx={{ marginLeft: '4px' }} />} />
  </MenuIconContainer>
);

export default JoinArea;
