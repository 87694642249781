import { useState } from 'react';
import { Button } from '@mui/material';
import { SxProps } from '@mui/system';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

const CopyButton = ({ copyText, sx }: { copyText: string; sx?: SxProps }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  };

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(console.error);
  };

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleCopyClick}
      startIcon={isCopied ? <DoneRoundedIcon /> : <ContentPasteRoundedIcon />}
      sx={{
        ...sx,
        minWidth: '100px'
      }}
    >
      {isCopied ? 'Copied!' : 'Copy'}
    </Button>
  );
};

CopyButton.defaultProps = {
  sx: undefined
};

export default CopyButton;
