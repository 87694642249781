import { Box, Card, CardContent, Link, Typography } from '@mui/material';
import { ModalUnstyled } from '@mui/base';
import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/system';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Offer } from '../../services/Interfaces';
import CopyButton from '../../components/CopyButton/CopyButton';

const VoucherCodeOfferModal = ({ offer }: { offer?: Offer }) => {
  const [voucherCodeModalOpen, setVoucherCodeModalOpen] = useState(false);

  const handleVoucherCodeModalOpen = () => setVoucherCodeModalOpen(true);
  const handleVoucherCodeModalClose = () => setVoucherCodeModalOpen(false);

  const theme = useTheme();

  useEffect(() => {
    if (offer) {
      handleVoucherCodeModalOpen();
    }
  }, [offer]);

  const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
  `;

  return (
    <StyledModal
      open={voucherCodeModalOpen}
      onClose={handleVoucherCodeModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
    >
      <Card
        sx={{
          width: '100%',
          maxWidth: theme.breakpoints.values.sm,
          margin: '6rem auto auto auto',
          border: '3px solid transparent',
          '&:focus': {
            outline: 'none',
            borderColor: theme.palette.primary.main
          }
        }}
      >
        <CardContent>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '1.2rem'
            }}
          >
            {offer?.title}
          </Typography>
          <Box
            sx={{
              border: `3px dotted ${theme.palette.primary.main}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 2,
              padding: '10px',
              fontWeight: 'bold'
            }}
          >
            <Box
              sx={{
                position: 'absolute'
              }}
            >
              {offer?.code}
            </Box>
            <CopyButton copyText={offer?.code || ''} sx={{ marginLeft: 'auto' }} />
          </Box>
          <Link
            href={offer?.url}
            target="_blank"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 2,
              fontWeight: 'bold'
            }}
          >
            Continue to {offer?.merchant.name}{' '}
            <ArrowForwardIosRoundedIcon sx={{ fontSize: '0.8rem', marginTop: '0.125rem', marginLeft: '0.25rem' }} />
          </Link>
        </CardContent>
      </Card>
    </StyledModal>
  );
};

VoucherCodeOfferModal.defaultProps = {
  offer: undefined
};

export default VoucherCodeOfferModal;
