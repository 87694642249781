import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';

const LetterButton = ({ text, href }: { text: string; href: string }) => {
  const LettersNavigationButton = styled(RouterLink)(({ theme }) => ({
    width: '2.5em',
    height: '2.5em',
    minWidth: 0,
    borderRadius: '50%',
    margin: '.1em',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase'
  }));

  return <LettersNavigationButton to={href}>{text}</LettersNavigationButton>;
};

export default LetterButton;
