import { Container, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  EMAIL_ENQUIRE,
  EMAIL_GDPR,
  PUBLIC_WEBSITE_URL_FULL,
  PUBLIC_WEBSITE_URL_SHORT,
  REGISTERED_COMPANY_ADDRESS,
  REGISTERED_COMPANY_NAME,
  REGISTERED_COMPANY_NUMBER,
  SITE_PUBLIC_SHORT_NAME
} from '../../configurations/constants';
import { getMetaTitle } from '../../services/utilities';

const PrivacyPolicy = () => {
  const theme = useTheme();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{getMetaTitle(`Privacy Policy`)}</title>
      </Helmet>
      <Container sx={{ mt: 4, mb: 4 }}>
        <article>
          <Typography variant="h1" sx={{ mb: 4 }}>
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            {SITE_PUBLIC_SHORT_NAME} values your privacy and is dedicated to safeguarding and preserving your privacy when using our website
            and/or application, visiting our website or communicating electronically with us.
          </Typography>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Who we are
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We are {SITE_PUBLIC_SHORT_NAME}, a trading name of {REGISTERED_COMPANY_NAME}. Company number {REGISTERED_COMPANY_NUMBER}.
              Registered office {REGISTERED_COMPANY_ADDRESS}. {SITE_PUBLIC_SHORT_NAME} is the controller of personal information that you
              contribute to this website, and is responsible for it&apos;s safe and proper use. All references in this policy to &quot;
              {SITE_PUBLIC_SHORT_NAME}&quot;, &quot;our&quot;, &quot;us&quot; or &quot;we&quot; refer to {SITE_PUBLIC_SHORT_NAME}, or our
              group companies, as appropriate. All references in this policy to &quot;our website&quot;, refer to the website owned by{' '}
              {SITE_PUBLIC_SHORT_NAME} at <Link to={PUBLIC_WEBSITE_URL_FULL}>{PUBLIC_WEBSITE_URL_SHORT}</Link>.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We value your privacy and want to be accountable and fair to you as well as transparent with you in the way that we collect
              and use your personal information.
            </Typography>
            <Typography sx={{ mb: 2 }}>This Privacy Policy, together with our Terms &amp; Conditions explains;</Typography>
            <ul>
              <li>What information we collect from you</li>

              <li>How your information is used</li>

              <li>How we store your information</li>

              <li>How you can access your information</li>
            </ul>
            <Typography sx={{ mb: 2 }}>
              By engaging with us in the ways set out in this Privacy Policy, you confirm that you have read and understood the entirety of
              this Privacy Policy. If you do not agree to these terms please do not continue using {SITE_PUBLIC_SHORT_NAME}.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              The information we collect from you
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We collect information from you in-order to show you the offers we think will be most relevant for you and give you the best
              possible service when using {SITE_PUBLIC_SHORT_NAME}. We collect and process the following information;
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Information you provide us
            </Typography>
            <Typography sx={{ mb: 2 }}>
              You may provide us with your personal information voluntarily. We may also receive information about you from third parties
              such as marketing agencies, market research companies, our suppliers, contractors, consultants, group companies, public
              websites and public agencies.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Registration information
            </Typography>
            <Typography sx={{ mb: 2 }}>
              When you download {SITE_PUBLIC_SHORT_NAME} and use a voucher on for the first time, we ask you to register and provide us with
              personal information as follows;
            </Typography>
            <Typography sx={{ mb: 2 }}>Your name, email address, date of birth, postcode and gender.</Typography>
            <Typography sx={{ mb: 2 }}>
              We use this information to better personalise our service for you. We will never rent, sell or share your personal information
              with any other company for its own marketing purposes without your consent.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Information you provide on our partners&apos; websites
            </Typography>
            <Typography sx={{ mb: 2 }}>
              You may find links to third party websites on our website. These hyperlinks are provided for your reference and convenience
              only and do not imply any endorsement of the activities of such third-party websites or any association with their operators.
              This Privacy Policy only applies to the personal information that we collect or which we receive from third party sources, and
              we cannot be responsible for personal information about you that is collected and stored by third parties. These websites
              should have their own privacy policies which you should check. We do not accept any responsibility or liability for their
              policies whatsoever as we have no control over them.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Information we get when you use our service
            </Typography>
            <Typography component="strong" variant="inherit">
              Your redemption information
            </Typography>
            <Typography sx={{ mb: 2 }}>
              When you use {SITE_PUBLIC_SHORT_NAME} we will collect information about how you use our site and application. For example,
              when you redeem a voucher we’ll record details of the offer, the date and time and the device you’ve redeemed it on. We’ll use
              this information to personalise the offers we show you in future.
            </Typography>
            <Typography component="strong" variant="inherit">
              Your location information
            </Typography>
            <Typography sx={{ mb: 2 }}>
              When you use our application and website we may receive your location or ask to use it. We’ll use this to show you the offers
              which are near you. If you’re using our application, depending on your handset or the network you subscribe to, Happy
              Vouchercodes may automatically receive this information.
            </Typography>
            <Typography component="strong" variant="inherit">
              Your mobile phone information
            </Typography>
            <Typography sx={{ mb: 2 }}>
              When you use the {SITE_PUBLIC_SHORT_NAME} application for the first time, we will receive a unique identifier for your phone
              if your handset manufacturer or network provide it. By downloading the {SITE_PUBLIC_SHORT_NAME} app you are consenting that we
              may use this information to send you push notifications about our offers. We will not pass these details to third parties.
            </Typography>
            <Typography component="strong" variant="inherit">
              Your IDFA/Advertising Identifier
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We also collect, where provided, your device&apos;s IDFA/Advertising Identifier which is an anonymous ID provided by your
              operating system. We may pass this anonymised data to a third party partner for purposes of providing accurate and relevant
              advertising across various operating systems and devices and to analyse the effectiveness of advertising campaigns.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Details of how to opt out of IDFA/AAID data sharing can be found on your mobile device operating settings.
            </Typography>
            <Typography component="strong" variant="inherit">
              Cookies and similar technologies
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Some pages on our website use cookies, which are small files placed on your internet browser when you visit our website. We
              use cookies in order to offer you a more tailored experience in the future, by understanding and remembering your particular
              browsing preferences.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Where we do use cookies on your website, you may block these at any time. To do so, you can activate the setting on your
              browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all
              cookies (including essential cookies), you may not be able to access all or parts of our website or to use all the
              functionality provided through our website.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              For detailed information on the cookies we use and the purposes for which we use them, please refer to our{' '}
              <Link to="/cookie-policy">Cookie Policy</Link>.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              How your information is used
            </Typography>
            <Typography component="strong" variant="inherit">
              To communicate with you
            </Typography>
            <Typography sx={{ mb: 2 }}>
              One of the main ways we use your information is to let you know about our offers and other promotions you might be interested
              in through email, push notifications and SMS messages.
            </Typography>

            <Typography component="strong" variant="inherit">
              Emails
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Once you become a {SITE_PUBLIC_SHORT_NAME} member we’ll send you email newsletters letting you know about our offers and
              deals. We may also send you other offers from our partners that we think you might be interested in. If you don’t wish to
              receive emails from us you can click the ‘unsubscribe’ link which will be found at the bottom of all emails from{' '}
              {SITE_PUBLIC_SHORT_NAME}. You will be sent to an ‘update your preferences’ page in your account where you can opt out of
              different types of email or click unsubscribe from all.
            </Typography>
            <Typography component="strong" variant="inherit">
              Directly communicating with {SITE_PUBLIC_SHORT_NAME}
            </Typography>
            <Typography sx={{ mb: 2 }}>
              When you send email or other communication to {SITE_PUBLIC_SHORT_NAME}, we may retain the communication in order to serve you.
            </Typography>
            <Typography component="strong" variant="inherit">
              To personalise the content you receive
            </Typography>
            <Typography sx={{ mb: 2 }}>
              By collecting data on your activities on our website and application we’ll be able to better understand the types of offers
              that you’re interested. We’ll then present the most relevant offers for you on our website, application and through the
              communications we send you.
            </Typography>
            <Typography component="strong" variant="inherit">
              To provide a better experience
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Our use of cookies and other similar technologies enables us to remember information about you when you visit our site. We use
              this to improve your experience by doing things like remembering your password, automatically logging you into your account
              and showing you adverts for offers that you’ve previously searched.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We also use your data for the improvement and maintenance of our website and to provide technical support for our website. We
              also use your data to evaluate your visit to the website and prepare reports or compile statistics to understand the type of
              people who use our website, how they use our website and to make our website more intuitive. Such details will be anonymised
              as far as reasonably possible and you will not be identifiable from the information collected.
            </Typography>
            <Typography component="strong" variant="inherit">
              To provide insight
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Our use of technologies, including Google Analytics enables us to gather statistical information about how people use our site
              and application. As well as using this to improve what we do, we also share it with the brands we work with to give them
              feedback. The information we share will not identify you personally, it is statistical data and does not identify any personal
              details whatsoever.
            </Typography>
            <Typography component="strong" variant="inherit">
              Other uses
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We may also collect, use and store your personal information for the following additional reasons:
            </Typography>
            <ul>
              <li>
                to deal with any enquiries or issues you have about how we collect, store and use your personal information, or any requests
                made by you for a copy of the information we hold about you. If we do not have a contract with you, we may process your
                personal information for these purposes where it is in our legitimate interests for customer services purposes;
              </li>

              <li>
                for internal corporate reporting, business administration, ensuring adequate insurance coverage for our business, ensuring
                the security of company facilities, research and development, and to identify and implement business efficiencies. We may
                process your personal information for these purposes where it is in our legitimate interests to do so;
              </li>

              <li>
                to comply with any procedures, laws and regulations which apply to us – this may include where we reasonably consider it is
                in our legitimate interests or the legitimate interests of others to comply, as well as where we are legally required to do
                so; and
              </li>

              <li>
                to establish, exercise or defend our legal rights – this may include where we reasonably consider it is in our legitimate
                interests or the legitimate interests of others, as well as where we are legally required to do so.
              </li>
            </ul>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Legal Basis for Processing
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We consider that the legal bases for using your personal information as set out in this Privacy Policy are as follows:
            </Typography>
            <ul>
              <li>
                our use of your personal information is necessary to perform our obligations under any contract with you (for example, to
                fulfil an order which you place with us, to comply with the terms of use of our website which you accept by browsing our
                website); or
              </li>

              <li>
                our use of your personal information is necessary for complying with our legal obligations (for example, for health and
                safety purposes); or
              </li>

              <li>
                where neither (a) nor (b) apply, use of your personal information is necessary for our legitimate interests or the
                legitimate interests of others (for example, to ensure the security of our website). Our legitimate interests are to:
                <ul>
                  <li>run, grow and develop our business;</li>

                  <li>operate our website {PUBLIC_WEBSITE_URL_SHORT};</li>

                  <li>carry out marketing, market research and business development;</li>

                  <li>place, track and ensure fulfilment of orders with our suppliers; and</li>

                  <li>for internal group administrative purposes</li>
                </ul>
              </li>
            </ul>
            <Typography sx={{ mb: 2 }}>
              If we rely on our (or another person&apos;s) legitimate interests for using your personal information, we will undertake a
              balancing test to ensure that our (or the other person&apos;s) legitimate interests are not outweighed by your interests or
              fundamental rights and freedoms which require protection of the personal information. You can ask us for information on this
              balancing test by using the contact details at the end of this Privacy Policy.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We may process your personal information in some cases for marketing purposes on the basis of your consent (which you may
              withdraw at any time after giving it, as described in this Privacy Policy).
            </Typography>
            <Typography sx={{ mb: 2 }}>
              If we rely on your consent for us to use your personal information in a particular way, but you later change your mind, you
              may withdraw your consent by contacting us at happyvcs-gdpr@gmail.com and we will stop doing so. However, if you withdraw your
              consent, this may impact the ability for us to be able to provide offers and discounts and associated services to you (for
              example, we may not be able to provide marketing information to you regarding our suppliers&apos; products and services).
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              How and why we share your personal information with others
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We may share your personal information with our group companies where it is in our legitimate interests to do so for internal
              administrative purposes (for example, for corporate strategy, compliance, auditing and monitoring, research and development
              and quality assurance).
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We will share your personal information with the following third parties or categories of third parties:
            </Typography>
            <ul>
              <li>
                we may share anonymised and aggregated statistical information with our suppliers to demonstrate what interest there has
                been in any marketing campaigns we have assisted our suppliers in carrying out;
              </li>

              <li>
                our other service providers and subcontractors, including payment processors, utility providers, suppliers of technical and
                support services, insurers, logistic providers, and cloud service providers;
              </li>

              <li>companies that assist in our marketing, advertising and promotional activities;</li>

              <li>
                analytics and search engine providers that assist us in the improvement and optimisation of our website; and Any third
                parties with whom we share your personal information are limited (by law and by contract) in their ability to use your
                personal information for any purpose other than to provide services for us. We will always ensure that any third parties
                with whom we share your personal information are subject to privacy and security obligations consistent with this Privacy
                Policy and applicable laws.
              </li>
            </ul>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              We will also disclose your personal information to third parties:
            </Typography>
            <ul>
              <li>
                where it is in our legitimate interests to do so to run, grow and develop our business:
                <ul>
                  <li>
                    if we sell or buy any business or assets, we may disclose your personal information to the prospective seller or buyer
                    of such business or assets;
                  </li>

                  <li>
                    if substantially all of our or any of our affiliates&apos; assets are acquired by a third party, in which case personal
                    information held by us will be one of the transferred assets;
                  </li>
                </ul>
              </li>
              <li>
                if we are under a duty to disclose or share your personal information in order to comply with any legal obligation, any
                lawful request from government or law enforcement officials and as may be required to meet national security or law
                enforcement requirements or prevent illegal activity;
              </li>

              <li>
                in order to enforce or apply our terms of use, our terms and conditions for customers or any other agreement or to respond
                to any claims, to protect our rights or the rights of a third party, to protect the safety of any person or to prevent any
                illegal activity; or
              </li>

              <li>
                to protect the rights, property, or safety of {SITE_PUBLIC_SHORT_NAME}, our staff, our customers or other persons. This may
                include exchanging personal information with other organisations for the purposes of fraud protection and credit risk
                reduction.
              </li>
            </ul>
            <Typography sx={{ mb: 2 }}>
              We may also disclose and use anonymised, aggregated reporting and statistics about users of our website or our goods and
              services for the purpose of internal reporting or reporting to our group or other third parties, and for our marketing and
              promotion purposes. None of these anonymised, aggregated reports or statistics will enable our users to be personally
              identified.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Save as expressly detailed above, we will never share, sell or rent any of your personal information to any third party
              without notifying you and, where necessary, obtaining your consent. If you have given your consent for us to use your personal
              information in a particular way, but later change your mind, you should contact us and we will stop doing so.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              How we store your information
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We keep your personal information for no longer than necessary for the purposes for which the personal information is
              processed. The length of time for which we retain personal information depends on the purposes for which we collect and use it
              and/or as required to comply with applicable laws and to establish, exercise or defend our legal rights.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Information Security
            </Typography>
            <Typography sx={{ mb: 2 }}>
              The main risk of our processing of your personal information is if it is lost, stolen or misused. This could lead to your
              personal information being in the hands of someone else who may use it fraudulently or make public, information that you would
              prefer to keep private.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              For this reason, {SITE_PUBLIC_SHORT_NAME} is committed to protecting your personal information from loss, theft and misuse. We
              take all reasonable precautions to safeguard the confidentiality of your personal information, including through use of
              appropriate organisational and technical measures.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              In the course of provision of your personal information to us, your personal information may be transferred over the internet.
              Although we make every effort to protect the personal information which you provide to us, the transmission of information
              over the internet is not completely secure. As such, you acknowledge and accept that we cannot guarantee the security of your
              personal information transmitted to our website and that any such transmission is at your own risk. Once we have received your
              personal information, we will use strict procedures and security features to prevent unauthorised access to it.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Where we have given you (or where you have chosen) a password which enables you to access your online account, you are
              responsible for keeping this password confidential. We ask you not to share a password with anyone.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Your Rights
            </Typography>
            <Typography sx={{ mb: 2 }}>
              You have certain rights in relation to your personal information. If you would like further information in relation to these
              or would like to exercise any of them, please contact us via email at [insert email address] at any time. You have the
              following rights:
            </Typography>
            <ul>
              <li>
                <Typography component="strong" variant="inherit">
                  Right of access
                </Typography>

                <Typography sx={{ mb: 2 }}>
                  You have a right of access to any personal information we hold about you. You can ask us for a copy of your personal
                  information; confirmation as to whether your personal information is being used by us; details about how and why it is
                  being used; and details of the safeguards which are in place if we transfer your information outside of the European
                  Economic Area (&quot;EEA&quot;).
                </Typography>
              </li>

              <li>
                <Typography component="strong" variant="inherit">
                  Right to update your information
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  You have a right to request an update to any of your personal information which is out of date or incorrect.
                </Typography>
              </li>

              <li>
                <Typography component="strong" variant="inherit">
                  Right delete your information
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  You have a right to ask us to delete any personal information which we are holding about you in certain specific
                  circumstances. You can ask us for further information on these specific circumstances by contacting us using the details
                  below. We will pass your request onto other recipients of your personal information unless that is impossible or involves
                  disproportionate effort. You can ask us who the recipients are, using the contact details below.
                </Typography>
              </li>
              <li>
                <Typography component="strong" variant="inherit">
                  Right to restrict use of your information
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  You have a right to ask us to restrict the way that we process your personal information in certain specific
                  circumstances. You can ask us for further information on these specific circumstances by contacting us using the details
                  below.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  We will pass your request onto other recipients of your personal information unless that is impossible or involves
                  disproportionate effort. You can ask us who the recipients are using the contact details below.
                </Typography>
              </li>

              <li>
                <Typography component="strong" variant="inherit">
                  Right to stop marketing
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  You have a right to ask us to stop using your personal information for direct marketing purposes. If you exercise this
                  right, we will stop using your personal information for this purpose.
                </Typography>
              </li>

              <li>
                <Typography component="strong" variant="inherit">
                  Right to data portability
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  You have a right to ask us to provide your personal information to a third party provider of services. This right only
                  applies where we use your personal information on the basis of your consent or performance of a contract; and where our
                  use of your information is carried out by automated means.
                </Typography>
              </li>

              <li>
                <Typography component="strong" variant="inherit">
                  Right to object
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  You have a right to ask us to consider any valid objections which you have to our use of your personal information where
                  we process your personal information on the basis of our or another person&apos;s legitimate interest.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  We will consider all such requests and provide our response within a reasonable period (and in any event within one month
                  of your request unless we tell you we are entitled to a longer period under applicable law). Please note, however, that
                  certain personal information may be exempt from such requests in certain circumstances, for example if we need to keep
                  using the information to comply with our own legal obligations or to establish, exercise or defend legal claims.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  If an exception applies, we will tell you this when responding to your request. We may request you provide us with
                  information necessary to confirm your identity before responding to any request you make.
                </Typography>
              </li>
            </ul>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Children
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Our website and services are not directed at children under the age of 13 and we do not knowingly collect any personal
              information from children under the age of 13.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              If you are a child under the age of 13 and we learn that we have inadvertently obtained personal information from you from our
              websites, or from any other source, then we will delete that information as soon as possible.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Please contact us at happyvcs-gdpr@gmail.com if you are aware that we may have inadvertently collected personal information
              from a child under the age of 13.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Marketing
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We may collect and use your personal information for undertaking marketing by email telephone and post.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              We may send you certain marketing communications (including electronic marketing communications) if it is in our legitimate
              interests to do so for marketing and business development purposes or, if you are a sole trader or a non-limited liability
              partnership if you have consented to receive such electronic marketing information.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              However, we will always obtain your consent to direct marketing communications where we are required to do so by law and if we
              intend to disclose your personal information to any third party for such marketing.{' '}
            </Typography>
            <Typography sx={{ mb: 2 }}>
              If you wish to stop receiving marketing communications, you can contact us by email at {EMAIL_GDPR}.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Where we may transfer your personal information
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Your personal information may be used, stored and/or accessed by staff operating outside the EEA working for us, other members
              of our group or suppliers. Further details on to whom your personal information may be disclosed are set out above.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              If we provide any personal information about you to any such non-EEA members of our group or suppliers, we will take
              appropriate measures to ensure that the recipient protects your personal information adequately in accordance with this
              Privacy Policy. These measures may include the following permitted in Articles 45 and 46 of the General Data Protection
              Regulation:
            </Typography>
            <ul>
              <li>
                in the case of US based entities, entering into European Commission approved standard contractual arrangements with them, or
                ensuring they have signed up to the EU-US Privacy Shield (see further https://www.privacyshield.gov/welcome); or
              </li>

              <li>
                in the case of entities based in other countries outside the EEA, entering into European Commission approved standard
                contractual arrangements with them.
              </li>

              <li>
                Further details on the steps we take to protect your personal information, in these cases is available from us on request by
                contacting us by email at [insert relevant contact details] at any time.
              </li>
            </ul>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Changes to this Policy
            </Typography>
            <Typography sx={{ mb: 2 }}>
              {SITE_PUBLIC_SHORT_NAME} reserves the right to change or modify this Privacy Policy. Any changes we make to our Privacy Policy
              in the future will be posted on this page and, where appropriate, you will be notified of any changes by post or email. Please
              check back frequently to see any updates or changes to our Privacy Policy.
            </Typography>
          </section>
          <section style={{ marginBottom: theme.spacing(4) }}>
            <Typography component="h2" variant="h3" sx={{ mb: 2, mt: 3 }}>
              Contacting Us
            </Typography>
            <Typography sx={{ mb: 2 }}>
              If you have any queries or complaints about our collection, use or storage of your personal information, or if you wish to
              exercise any of your rights in relation to your personal information, please contact {EMAIL_ENQUIRE}. We will investigate and
              attempt to resolve any such complaint or dispute regarding the use or disclosure of your personal information.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              In accordance with Article 77 of the General Data Protection Regulation, you may also make a complaint to the Information
              Commissioner&apos;s Office, or the data protection regulator in the country where you usually live or work, or where an
              alleged infringement of the General Data Protection Regulation has taken place. Alternatively, you may seek a remedy through
              the courts if you believe your rights have been breached.
            </Typography>
          </section>
          <Typography variant="caption" component="p" sx={{ mb: 1, mt: 4 }}>
            The practices described in this Privacy Policy statement are current as of 25/2/2021.
          </Typography>
        </article>
      </Container>
    </HelmetProvider>
  );
};

export default PrivacyPolicy;
