import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import defaultImage from '../../assets/images/head-default.jpg';

const HeadTitle = ({ title, image }: { title: string; image?: string }) => (
  <Container>
    <Typography variant="h1" mt={4} mb={4}>
      {title}
    </Typography>
    <Box sx={{ position: 'relative' }}>
      <img src={image} alt={title} style={{ width: '100%' }} />
    </Box>
  </Container>
);

HeadTitle.defaultProps = {
  image: defaultImage
};

export default HeadTitle;
