import { createTheme, responsiveFontSizes } from '@mui/material';

declare module '@mui/material/Button' {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsVariantOverrides {
    topNav: true;
    cta: true;
  }
}

const palette = {
  primary: '#0031FF',
  secondary: '#00D4A9',
  text: '#333333'
};

const defaultTheme = createTheme({
  palette: {
    primary: { main: palette.primary },
    secondary: { main: palette.secondary },
    text: { primary: palette.text }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'topNav' },
          style: {
            textTransform: 'none',
            fontSize: '0.875rem'
          }
        },
        {
          props: { variant: 'cta' },
          style: {
            backgroundColor: palette.secondary,
            color: palette.primary,
            borderRadius: '50px',
            fontSize: '0.875rem',
            ':hover': {
              backgroundColor: palette.secondary
            }
          }
        }
      ],
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        endIcon: {
          marginLeft: 0
        }
      }
    }
  },
  typography: {
    fontFamily: 'inherit',
    h1: { fontWeight: 500, fontSize: '3rem' },
    h2: { fontWeight: 500, fontSize: '2.6rem' },
    h3: { fontWeight: 500, fontSize: '2.2rem' },
    h4: { fontWeight: 500, fontSize: '1.8rem' },
    h5: { fontWeight: 500, fontSize: '1.4rem' },
    h6: { fontWeight: 500, fontSize: '1rem' }
  }
});

const responsiveDefaultTheme = responsiveFontSizes(defaultTheme);

export default responsiveDefaultTheme;
